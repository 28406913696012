import styled from "styled-components"
import { colors } from "../../../utils/colors";
import TrainLinesTable from "./LinesTable";
import StationsTable from "./StationsTable";
import { useEffect, useState } from "react";
import { DropdownM } from "../../Controls/Dropdowns";
import * as TypographyStyles from '../../TypographyStyles';
import KPIBox from "../../JourneyDetailsScreen/KPI";
import { getColor } from "../../../utils/colorFormatter";
import { ButtonM } from "../../Controls/Buttons";
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';


// Line data imports
import deLineData from '../../../data/lineRanking_filtered_DE.csv';
import atLineData from '../../../data/lineRanking_filtered_AT.csv';
import beLineData from '../../../data/lineRanking_filtered_BE.csv';
import frLineData from '../../../data/lineRanking_filtered_FR.csv';
import nlLineData from '../../../data/lineRanking_filtered_NL.csv';
import chLineData from '../../../data/lineRanking_filtered_CH.csv';
import itLineData from '../../../data/lineRanking_filtered_IT.csv';

// Station data imports
import deStationData from '../../../data/stationRanking_filtered_DE.csv';
import atStationData from '../../../data/stationRanking_filtered_AT.csv';
import beStationData from '../../../data/stationRanking_filtered_BE.csv';
import frStationData from '../../../data/stationRanking_filtered_FR.csv';
import nlStationData from '../../../data/stationRanking_filtered_NL.csv';
import chStationData from '../../../data/stationRanking_filtered_CH.csv';
import itStationData from '../../../data/stationRanking_filtered_IT.csv';


interface TrainLine {
  lineName: string;
  origin: string;
  destination: string;
  grandTotalArrivalDelay: number;
  numberOfTrips: number;
  avgDelayPerTrip: number;
  cancellationRate: number;
  reliabilityScore: number;
}

interface Station {
  stopName: string;
  avgArrivalDelay: number;
}

type CountryCode = 'DE' | 'AT' | 'BE' | 'FR' | 'NL' | 'CH' | 'IT';

const lineDataMap: Record<CountryCode, string> = {
  DE: deLineData,
  AT: atLineData,
  BE: beLineData,
  FR: frLineData,
  NL: nlLineData,
  CH: chLineData,
  IT: itLineData,
};

const stationDataMap: Record<CountryCode, string> = {
  DE: deStationData,
  AT: atStationData,
  BE: beStationData,
  FR: frStationData,
  NL: nlStationData,
  CH: chStationData,
  IT: itStationData,
};

const WrappedContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: clamp(1rem, 5vw, 2.25rem);
  gap: clamp(1rem, 3vw, 1.5rem);
  background: ${colors.keyBackground};
  @media (min-width: 768px) {
    padding: 64px 64px;  // Increased horizontal padding for desktop
  }

  > * {
    max-width: 1200px;
    margin: 0 auto;
  }
`;

const ContainerCard = styled('div')`
    display: flex;
    padding: 20px;
    flex-direction: column;
    align-items: flex-start;
    gap: 28px;
    align-self: stretch;
    border-radius: 20px;
    border-bottom: 2px solid ${colors.grey400};
    background: ${colors.white};
    box-sizing: border-box
`

const CardHeader = styled('div')`
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 8px;
`

const CardTitle = styled('div')`
    display: flex;
    align-items: flex-start;
    gap: 4px;
`

const CardDescription = styled('div')`
    display: flex;
    align-items: flex-start;
    gap: 4px;
`

const KPIsContainer = styled('div')`
  display: flex;
  gap: 20px;
  align-items: flex-start;
  align-content: flex-start;
  align-self: stretch;
  flex-wrap: wrap;
`;

const DropdownWrapper = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
  margin-top: 16px;
`;

const TableWrapper = styled('div')`
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
`;

const CTAsContainersWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media (min-width: 768px) {
    flex-direction: row;
  }
`;

const CTAsContainer = styled('div')<{ backgroundColor?: string }>`
  display: flex;
  padding: 36px 34px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 28px;
  background: ${({ backgroundColor }) => backgroundColor || colors.grey200};
  
  @media (min-width: 768px) {
    padding: 64px 64px;
    flex: 1;
  }
`;

const CTAContent = styled('div')`
  display: flex;  
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
`;


const WrappedPage = () => {
  const [lineData, setLineData] = useState<TrainLine[]>([]);
  const [selectedCountry, setSelectedCountry] = useState<CountryCode>('DE');
  const [stationData, setStationData] = useState<Station[]>([]);
  const reliabilityColor = (value: number) => getColor(value, 'punctualityPercentage');
  const navigate = useNavigate();
  
  const handleNavigation = (path: string) => {
    navigate(path);
  };

  // Scroll to top on component mount
  useEffect(() => {
      window.scrollTo(0, 0); // Scroll to the top of the page
  }, []);
  
  useEffect(() => {
    const parseCSVData = <T,>(csvString: string): T[] => {
      const lines = csvString.split('\n');
      const headers = lines[0].split(';');
      return lines.slice(1)
        .filter(line => line.trim() !== '')
        .map(line => {
          const values = line.split(';');
          const obj: Record<string, string | number> = {};
          headers.forEach((header, index) => {
            const rawValue = values[index]?.trim() || '';
            const cleanHeader = header.trim();
            if (rawValue && !isNaN(parseFloat(rawValue.replace(',', '.')))) {
              obj[cleanHeader] = parseFloat(rawValue.replace(',', '.'));
            } else {
              obj[cleanHeader] = rawValue;
            }
          });
          return obj as unknown as T;
        });
    };

    const loadData = async () => {
      try {
        // Load line data
        const lineResponse = await fetch(lineDataMap[selectedCountry]);
        const lineText = await lineResponse.text();
        const parsedLineData = parseCSVData<TrainLine>(lineText);
        setLineData(parsedLineData);

        // Load station data
        const stationResponse = await fetch(stationDataMap[selectedCountry]);
        const stationText = await stationResponse.text();
        const parsedStationData = parseCSVData<Station>(stationText);
        setStationData(parsedStationData);
      } catch (error) {
        console.error('Error loading CSV:', error);
      }
    };

    loadData();
  }, [selectedCountry]);

  const handleCountryChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedCountry(e.target.value as CountryCode);
  };
  
  
  return (
    <>
    <Helmet>
      <title>chuuchuu 2024 Wrapped - train delays and cancellations</title>
      <meta name="title" content="chuuchuu 2024 Wrapped - train delays and cancellations" />
      <meta name="description" content="Find out which countries have the highest and lowest train punctuality" />
      
      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://chuuchuu.com/2024wrapped" />
      <meta property="og:title" content="chuuchuu 2024 Wrapped - train delays and cancellations" />
      <meta property="og:description" content="Find out which countries have the highest and lowest train punctuality" />
      <meta property="og:image" content="https://chuuchuu.com/images/previewpage.jpg" />
      
      <meta name="twitter:card" content="summary_large_image" />
    </Helmet>
    <div>
      <WrappedContainer>
        <TypographyStyles.PageTitle color={colors.keyDarkest}>chuuchuu Wrapped 2024</TypographyStyles.PageTitle>
        <TypographyStyles.BodyLRegular color={colors.keyDarkest}>We've analyzed tens of thousands of train trips across Europe's busiest rail networks to reveal the hits and misses of 2024. From Paris to Vienna, Amsterdam to Zürich, dive into our interactive rankings to discover which stations kept passengers waiting and which trains consistently hit the right notes. Compare performance across six major European rail networks – Germany, France, the Netherlands, Belgium, Switzerland, and Austria – and see how your latest train trip stacks up against the competition.</TypographyStyles.BodyLRegular>
        <ContainerCard>
          <CardHeader>
              <CardTitle>
                  <TypographyStyles.BodyXLSemibold color={colors.black}>Punctuality</TypographyStyles.BodyXLSemibold>
                  <TypographyStyles.BodyXLRegular color={colors.grey600}>
                      per country
                  </TypographyStyles.BodyXLRegular>
              </CardTitle>
              <CardDescription>
                  <TypographyStyles.BodyLRegular color={colors.grey600}>
                    % trains arriving with 5 min or less delay. Including long distance and intercity trains, night trains, as well as local and regional trains in the Netherlands and Belgium. Counting arrival delay at every stop, not only at the final destination.
                  </TypographyStyles.BodyLRegular>
              </CardDescription>
          </CardHeader>
          <KPIsContainer>
            <KPIBox
              icon={{ type: 'emoji', symbol: "🇨🇭"}}
              title="Switzerland"
              value={`95%`}
              colorCondition={reliabilityColor}
              isReliability={false}
            />
            <KPIBox
              icon={{ type: 'emoji', symbol: "🇳🇱"}}
              title="Netherlands"
              value={`94%`}
              colorCondition={reliabilityColor}
              isReliability={false}
            />
            <KPIBox
              icon={{ type: 'emoji', symbol: "🇧🇪"}}
              title="Belgium"
              value={`90%`}
              colorCondition={reliabilityColor}
              isReliability={false}
            />
            <KPIBox
              icon={{ type: 'emoji', symbol: "🇫🇷"}}
              title="France"
              value={`89%`}
              colorCondition={reliabilityColor}
              isReliability={false}
            />
            <KPIBox
              icon={{ type: 'emoji', symbol: "🇮🇹"}}
              title="Italy"
              value={`80%`}
              colorCondition={reliabilityColor}
              isReliability={false}
            />
            <KPIBox
              icon={{ type: 'emoji', symbol: "🇦🇹"}}
              title="Austria"
              value={`75%`}
              colorCondition={reliabilityColor}
              isReliability={false}
            />
            <KPIBox
              icon={{ type: 'emoji', symbol: "🇩🇪"}}
              title="Germany"
              value={`54%`}
              colorCondition={reliabilityColor}
              isReliability={false}
            />
          </KPIsContainer>
        </ContainerCard>
        <DropdownWrapper>
          <TypographyStyles.PageSubtitleSemiBold color={colors.keyDarker}>See detailed statistics for</TypographyStyles.PageSubtitleSemiBold>
          <DropdownM
            value={selectedCountry}
            onChange={handleCountryChange}
          >
            <option value="AT">🇦🇹 Austria</option>
            <option value="BE">🇧🇪 Belgium</option>
            <option value="FR">🇫🇷 France</option>
            <option value="DE">🇩🇪 Germany</option>
            <option value="IT">🇮🇹 Italy</option>
            <option value="NL">🇳🇱 Netherlands</option>
            <option value="CH">🇨🇭 Switzerland</option>
          </DropdownM>
        </DropdownWrapper>
        <TableWrapper><TrainLinesTable data={lineData} /></TableWrapper>
        <TableWrapper><StationsTable data={stationData} /></TableWrapper>
      </WrappedContainer>
      <CTAsContainersWrapper>
        <CTAsContainer backgroundColor={colors.keyHover}>
          <CTAContent>
            <TypographyStyles.PageTitleSemiBold color={colors.keyDarkest}>No more surprises</TypographyStyles.PageTitleSemiBold>
            <TypographyStyles.BodyXLRegular color={colors.keyDarker} style={{ textAlign: 'center' }}>Find the train journey with the lowest chance of disruption</TypographyStyles.BodyXLRegular>
          </CTAContent>
          <ButtonM onClick={() => handleNavigation('/')}>Find reliable trains</ButtonM>
        </CTAsContainer>
        <CTAsContainer backgroundColor={colors.keyDarker}>
          <CTAContent>
            <TypographyStyles.PageTitleSemiBold color={colors.white}>Dive deeper</TypographyStyles.PageTitleSemiBold>
            <TypographyStyles.BodyXLRegular color={colors.keyBackground} style={{ textAlign: 'center' }}>Make use of our delay data for your own use cases</TypographyStyles.BodyXLRegular>
          </CTAContent>
          <ButtonM onClick={() => handleNavigation('/data')}>Get the data</ButtonM>
        </CTAsContainer>
      </CTAsContainersWrapper>
    </div>
    </>
  );
};

export default WrappedPage;