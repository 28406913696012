import styled from "styled-components";
import { colors } from "../../utils/colors";
import * as TypographyStyles from '../TypographyStyles';
import { useEffect } from "react";


const TermsContainer = styled('div')`
  display: flex;
  flex-direction: column;
  padding: 40px 20px;
  align-items: flex-start;
  gap: 24px;
  background-color: ${colors.grey200}; // Light background for readability
  @media (min-width: 768px) {
    padding: 64px 64px;  // Increased horizontal padding for desktop
  }
`;

const Section = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const SubSection = styled('div')`
  margin-top: 6px;
`;

const Title = styled(TypographyStyles.PageSubtitleSemiBold)`
  color: ${colors.keyDarkest};
`;

const SubTitle = styled(TypographyStyles.BodyLSemibold)`
  color: ${colors.keyDarker};
`;

const Paragraph = styled(TypographyStyles.BodyLRegular)`
  color: ${colors.grey800};
  margin-top: 8px;
`;

const TermsConditionsPage = () => {
  // Scroll to top on component mount
  useEffect(() => {
      window.scrollTo(0, 0); // Scroll to the top of the page
  }, []);

    return (
      <TermsContainer>
        <TypographyStyles.PageTitle color={colors.keyDarker}>Terms and conditions</TypographyStyles.PageTitle>
        
        <Section>
          <TypographyStyles.PageSubtitleSemiBold color={colors.keyDarkest}>
            Last updated: 9/10/2024
          </TypographyStyles.PageSubtitleSemiBold>
          <TypographyStyles.BodyLRegular color={colors.grey800}>
            Welcome to Chuuchuu! These terms and conditions (“Terms”, “Terms and Conditions”) govern your use of the Chuuchuu website (“App”), operated by Chuuchuu (“us”, “we”, or “our”). By accessing or using Chuuchuu, you agree to be bound by these Terms. If you disagree with any part of the Terms, you may not use the App.
          </TypographyStyles.BodyLRegular>
        </Section>
  
        <Section>
          <Title>1. Use of the App</Title>
          <SubSection>
            <Paragraph>By using Chuuchuu, you agree to comply with these terms:</Paragraph>
          </SubSection>
          
          <SubSection>
            <SubTitle>1.1. Eligibility</SubTitle>
            <Paragraph>
              <br />By using Chuuchuu, you confirm that you are at least 18 years old or have obtained the necessary permissions from your legal guardian to use the app if you are under 18.
            </Paragraph>
          </SubSection>

          <SubSection>
            <SubTitle>1.2. Account Registration</SubTitle>
            <Paragraph>
              <br />To use certain features of Chuuchuu, you may need to create an account. You are responsible for maintaining the confidentiality of your account details and for all activities that occur under your account.
            </Paragraph>
          </SubSection>

          <SubSection>
            <SubTitle>1.3. Acceptable Use</SubTitle>
            <Paragraph>
              <br />You agree not to use Chuuchuu for any unlawful purposes or in a way that infringes the rights of others. You also agree not to engage in harmful, abusive, or disruptive behavior while using the App.
            </Paragraph>
          </SubSection>

          <SubSection>
            <SubTitle>1.4. Content Restrictions</SubTitle>
            <Paragraph>
              <br />You are responsible for all content that you upload, share, or display through the App. You agree that you will not post any content that is offensive, defamatory, or otherwise violates the rights of third parties.
            </Paragraph>
          </SubSection>
        </Section>
  
        <Section>
            <TypographyStyles.PageSubtitleSemiBold color={colors.keyDarkest}>
                2. Privacy
            </TypographyStyles.PageSubtitleSemiBold>
            <TypographyStyles.BodyLRegular color={colors.grey800}>
              Your privacy is important to us. Please refer to our Privacy Policy for more information about how we collect, use, and share your personal data.
            </TypographyStyles.BodyLRegular>
        </Section>
  
        <Section>
          <TypographyStyles.PageSubtitleSemiBold color={colors.keyDarkest}>
            3. Journey Planning and Information Accuracy
          </TypographyStyles.PageSubtitleSemiBold>
          <SubSection>
            <SubTitle>3.1. No Guarantee of Accuracy</SubTitle>
            <Paragraph>
              <br />Chuuchuu provides journey planning and real-time information based on data from third-party sources, and while we strive to ensure that this information is up-to-date and reliable, we do not guarantee its accuracy, completeness, or timeliness. You acknowledge and agree that your use of this information is at your own risk, and Chuuchuu will not be held responsible for any inaccuracies or delays in the data.
            </Paragraph>
          </SubSection>
          <SubSection>
            <SubTitle>3.2. Statistical Information</SubTitle>
            <Paragraph>
              <br />Chuuchuu may provide statistical insights and data layered on top of journey planning and real-time information. While we aim to provide accurate and reliable statistics, we do not claim full accuracy or guarantee that the statistics provided are free from errors. Any use of this statistical information is at your own discretion and risk.
            </Paragraph>
          </SubSection>
          <SubSection>
            <SubTitle>3.2. Service Availability</SubTitle>
            <Paragraph>
              <br />We do not guarantee that all functionality, including but not limited to journey planning tools, real-time data, or statistical insights, will be continuously available. Features and statistics may be modified, updated, or discontinued at any time without prior notice. Chuuchuu reserves the right to remove or alter any aspect of the App, including statistical data, without liability.
            </Paragraph>
          </SubSection>
        </Section>
  
        <Section>
          <TypographyStyles.PageSubtitleSemiBold color={colors.keyDarkest}>
            4. Subscriptions and Payments
          </TypographyStyles.PageSubtitleSemiBold>
          <SubSection>
            <SubTitle>4.1. Free and Paid Services</SubTitle>
            <Paragraph>
              <br />Chuuchuu may offer both free and paid features or services. Any charges for paid services will be clearly disclosed to you before you complete a transaction.
            </Paragraph>
          </SubSection>
          <SubSection>
            <SubTitle>4.2. Subscription Plans</SubTitle>
            <Paragraph>
              <br />Certain features may be available via subscription plans. You agree to pay all fees associated with your subscription. You may cancel your subscription at any time through the App or your device’s app store.
            </Paragraph>
          </SubSection>
          <SubSection>
            <SubTitle>4.2. Refund Policy</SubTitle>
            <Paragraph>
              <br />Payments made for subscriptions are non-refundable, except as required by law or as described in our refund policy.
            </Paragraph>
          </SubSection>
        </Section>
  
        <Section>
          <TypographyStyles.PageSubtitleSemiBold color={colors.keyDarkest}>
            5. Intellectual Property
          </TypographyStyles.PageSubtitleSemiBold>
          <SubSection>
            <SubTitle>5.1. App Content</SubTitle>
            <Paragraph>
              <br />All content provided by Chuuchuu, including but not limited to text, images, and software, is the intellectual property of Chuuchuu or its licensors. You are granted a limited, non-exclusive, non-transferable license to use the App for personal purposes only.
            </Paragraph>
          </SubSection>
          <SubSection>
            <SubTitle>5.2. User-Generated Content</SubTitle>
            <Paragraph>
              <br />Any content you create and submit to Chuuchuu remains your property, but by submitting it, you grant us a worldwide, non-exclusive, royalty-free license to use, reproduce, distribute, and display that content for purposes of operating the App.
            </Paragraph>
          </SubSection>
        </Section>

        <Section>
          <TypographyStyles.PageSubtitleSemiBold color={colors.keyDarkest}>
            6. Termination
          </TypographyStyles.PageSubtitleSemiBold>
          <TypographyStyles.BodyLRegular color={colors.grey800}>
            We reserve the right to terminate or suspend your access to Chuuchuu at our discretion, with or without notice, for conduct that we believe violates these Terms or is otherwise harmful to our services or other users.
          </TypographyStyles.BodyLRegular>
        </Section>

        <Section>
          <TypographyStyles.PageSubtitleSemiBold color={colors.keyDarkest}>
            7. Disclaimers and Limitation of Liability
          </TypographyStyles.PageSubtitleSemiBold>
          <SubSection>
            <SubTitle>7.1. Disclaimer of Warranties</SubTitle>
            <Paragraph>
              <br />Chuuchuu is provided on an “as is” and “as available” basis without warranties of any kind, either express or implied. We do not guarantee that the App will be uninterrupted, secure, or free from errors.
            </Paragraph>
          </SubSection>
          <SubSection>
            <SubTitle>7.2. Limitation of Liability</SubTitle>
            <Paragraph>
              <br />To the maximum extent permitted by law, Chuuchuu and its affiliates shall not be liable for any damages arising out of your use or inability to use the App.
            </Paragraph>
          </SubSection>
        </Section>

        <Section>
          <TypographyStyles.PageSubtitleSemiBold color={colors.keyDarkest}>
            8. Changes to These Terms
          </TypographyStyles.PageSubtitleSemiBold>
          <TypographyStyles.BodyLRegular color={colors.grey800}>
            We reserve the right to modify or replace these Terms at any time. If we make material changes, we will provide you with notice through the App or by other means prior to the changes taking effect. Your continued use of the App after the changes are made constitutes your acceptance of the new Terms.
          </TypographyStyles.BodyLRegular>
        </Section>

        <Section>
          <TypographyStyles.PageSubtitleSemiBold color={colors.keyDarkest}>
            9. Governing Law
          </TypographyStyles.PageSubtitleSemiBold>
          <TypographyStyles.BodyLRegular color={colors.grey800}>
            These Terms are governed by and construed in accordance with the laws of Belgium, without regard to its conflict of law provisions. You agree to submit to the exclusive jurisdiction of the courts located within Belgium to resolve any legal matters arising from these Terms.
          </TypographyStyles.BodyLRegular>
        </Section>
  
        <Section>
          <TypographyStyles.PageSubtitleSemiBold color={colors.keyDarkest}>
            10. Contact Us
          </TypographyStyles.PageSubtitleSemiBold>
          <TypographyStyles.BodyLRegular color={colors.grey800}>
          If you have any questions about these Terms, please contact us at:
            <br />
            <br />
            Chuuchuu
            <br />
            info@chuuchuu.com
          </TypographyStyles.BodyLRegular>
        </Section>
      </TermsContainer>
    );
  };
  
  export default TermsConditionsPage;