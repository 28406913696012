import styled from "styled-components";
import * as TypographyStyles from '../TypographyStyles';
import { StyledTooltip } from '../TooltipStyles';
import { formatDuration, getDurationInMinutes } from "../../utils/durationUtils";
import { Leg } from "../../utils/mapJourneysData";
import { colors } from "../../utils/colors";
import AmenityIcon from "./AmenityIcon";
import info from '../../icons/info.svg'

import TimeContainer from '../TimeContainer'
import Location from "./Location";
import LineBadge from "../LineBadge";
import Statistics from "./Statistics";

const Container = styled('div')`
 display: flex;
 flex-direction: column;
 padding: 16px;
 align-items: flex-start;
 gap: 12px;
 align-self: stretch;
 border-radius: 12px;
 border-bottom: 2px solid ${colors.grey400};
 background: ${colors.white};
 padding-left: 48px;
 position: relative;
 grid-row-gap: 2px;
 &:before {
   content: "";
   position: absolute;
   width: 2px;
   height: calc(100% - 89px);
   background-color: ${colors.grey600};
   left: 25px;
   top: 54px;
 }
`

const LineContainer = styled('div')`
 margin-top: 12px;
 display: flex;
 flex-direction: column;
 grid-row-gap: 12px;
 margin-bottom: 12px;
`

const BadgeContainer = styled('div')`
 display: flex;
 align-items: center;
 grid-column-gap: 10px;
`

const AmenitiesContainer = styled('div')`
 display: flex;
 align-items: center;
 grid-column-gap: 8px;
`

interface DestinationContainerProps {
  hasDirection?: boolean;
}

const DestinationContainer = styled('div')<DestinationContainerProps>`
 display: flex;
 ${({ hasDirection }) => hasDirection && `
   grid-column-gap: 10px;
 `}
`

const InfoContainer = styled('div')`
 color: ${colors.infoBlue};
 display: flex;
 grid-column-gap: 4px;
 align-items: center;
 & > img {
   height: 12px;
 }
}
`

const StatsContainer = styled('div')`
 margin-bottom: 14px;
 width: 100%;
`

type JourneyLegCardProps = {
  leg: Leg;
}

const JourneyLegCard = ({ leg }: JourneyLegCardProps) => {
  const remark = leg.remarks?.find((remark: { code?: string }) => remark.code === null)

  return (
    <Container>
      <TimeContainer time={leg.plannedDeparture} delay={leg.departureDelay} averageDelay={leg.depDelay?.averageDelay} />
      <Location name={leg.origin.name} platform={leg.departurePlatform} plannedPlatform={leg.plannedDeparturePlatform} />
      <LineContainer>
        <BadgeContainer>
          <LineBadge lineName={leg.line.name} occupancy={leg.loadFactor} />
          <AmenitiesContainer>
            {leg.remarks?.map((remark: { code?: string; }) => <AmenityIcon code={remark.code} />)}
          </AmenitiesContainer>
        </BadgeContainer>
        <DestinationContainer hasDirection={!!leg.direction}>
          <TypographyStyles.BodySRegular color={colors.grey800}>
            {leg.direction ? `to ${leg.direction}` : ''}
          </TypographyStyles.BodySRegular>
          <TypographyStyles.BodySRegular color={colors.grey600}>
            {formatDuration(getDurationInMinutes(leg.plannedDeparture, leg.plannedArrival))}
          </TypographyStyles.BodySRegular>
        </DestinationContainer>
        {remark ? (
          <>
            <InfoContainer data-tooltip-id={`remark-${leg.id}-${leg.line.name}`}>
              <img alt="info" src={info} />
              <TypographyStyles.BodySRegular>{remark.summary}</TypographyStyles.BodySRegular>
            </InfoContainer>
            <StyledTooltip id={`remark-${leg.id}-${leg.line.name}`} place="top" content={remark.text} className="tooltip-styled">
            </StyledTooltip>
          </>
        ) : null}
      </LineContainer>
      {(leg.arrDelay || leg.depDelay) && <StatsContainer><Statistics leg={leg} /></StatsContainer>}
      <TimeContainer time={leg.plannedArrival} delay={leg.arrivalDelay} averageDelay={leg.arrDelay?.averageDelay} />
      <Location name={leg.destination.name} platform={leg.arrivalPlatform} plannedPlatform={leg.plannedArrivalPlatform} />
    </Container>
  )
}

export default JourneyLegCard;