import './App.css';
import { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useParams, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import './fonts/fonts.css'

import { MappedJourney } from './utils/mapJourneysData';
import { fetchJourneys } from './utils/fetchJourneys';

import SearchControls from './components/SearchControls';
import HomeScreenContent from './components/HomeScreen/HomeScreenContent';
import { JourneyList } from './components/JourneyList';
import JourneyDetailsScreen from './components/JourneyDetailsScreen';
import { Message } from './components/Message';
import TrainStatisticsScreen from './components/StatisticsPage/TrainStatisticsScreen';
import TransferStatisticsScreen from './components/StatisticsPage/TransferStatisticsScreen';
import FooterSection from './components/FooterSection/FooterSection';
import Recents from './components/Recents';
import Header from './components/Header/Header';
import APIPage from './components/ContentPages/DataPage';
import PrivacyPolicyPage from './components/ContentPages/Privacy';
import TermsConditionsPage from './components/ContentPages/Terms';
import ContactPage from './components/ContentPages/Contact';
import WrappedPage from './components/ContentPages/WrappedPage/WrappedPage';

const Container = styled('div')`
  display: flex;
  flex-direction: column;
`

interface SearchControlsProps {
  setOrigin: React.Dispatch<React.SetStateAction<string>>;
  setDestination: React.Dispatch<React.SetStateAction<string>>;
  setOriginName: React.Dispatch<React.SetStateAction<string>>; // Add this
  setDestinationName: React.Dispatch<React.SetStateAction<string>>; // Add this
  setDate: React.Dispatch<React.SetStateAction<string>>;
  setTripType: React.Dispatch<React.SetStateAction<'departure' | 'arrival'>>;
  setOptions: React.Dispatch<React.SetStateAction<Record<string, string>>>;
  handleFetchJourneys: (type: 'earlier' | 'later' | 'initial') => void;
  recentSearches: any[];
  screen: 'home' | 'journeys';  // Add this line if you're distinguishing between different screens
  originId: string;  // Add this line
  destinationId: string;  // Add this line
  originName: string;  // Add this line
  destinationName: string;  // Add this line
  date: string;  // Add this line
  tripType: 'departure' | 'arrival';  // Add this line
  options: Record<string, string>;  // Add this line
}

interface JourneysScreenProps extends SearchControlsProps {
  journeys: MappedJourney[];
  loading: boolean;
  hasError: boolean;
  errorMessage: string;
  earlierThan: string | null;
  laterThan: string | null;
  recentSearches: any[];
}

interface RecentSearch {
  originId: string;
  destinationId: string;
  originName: string;
  destinationName: string;
  date: string;
  tripType: string;
  options: Record<string, string>;
  timestamp: string;
}

const HomeScreen: React.FC<SearchControlsProps> = ({
  setOrigin,
  setDestination,
  setOriginName,
  setDestinationName,
  setDate,
  setTripType,
  setOptions,
  handleFetchJourneys,
  recentSearches,
  originId,      // Pass these values to SearchControls
  destinationId, // Add this line
  originName,  // Add this line
  destinationName, // Add this line
  date,        // Add this line
  tripType,    // Add this line
  options,     // Add this line
}) => {
  const navigate = useNavigate();
  const [shouldFetch, setShouldFetch] = useState(false);

  // Effect to trigger fetch when shouldFetch is true
  useEffect(() => {
    if (shouldFetch) {
      // Call fetchJourneys here with updated parameters
      handleFetchJourneys('initial');
      // Navigate to journeys page only after fetching
      navigate('/journeys', { state: { from: '/' } });
      // Reset the fetch trigger
      setShouldFetch(false);
    }
  }, [shouldFetch, handleFetchJourneys, navigate]);

  // Scroll to top on component mount
  useEffect(() => {
      window.scrollTo(0, 0); // Scroll to the top of the page
  }, []);

  // Function to handle clicking on a recent search
  const handleRecentSearch = (search: RecentSearch) => {
    // Update state based on recent search
    setOrigin(search.originId);
    setDestination(search.destinationId);
    setOriginName(search.originName);
    setDestinationName(search.destinationName);
    setDate(search.date);
    setTripType(search.tripType as 'departure' | 'arrival');
    setOptions(search.options);

    // Trigger the fetch
    setShouldFetch(true);
  };

  return (
    <>
      <SearchControls
        setOrigin={setOrigin}
        setDestination={setDestination}
        setOriginName={setOriginName}  // Pass the setter to SearchControls
        setDestinationName={setDestinationName}  // Pass the setter to SearchControls
        setDate={setDate}
        setTripType={setTripType}
        setOptions={setOptions}
        fetchJourneys={() => setShouldFetch(true)}
        screen='home'
        originId={originId}  // Pass the current values
        destinationId={destinationId}  // Add this line
        originName={originName}  // Add this line
        destinationName={destinationName}  // Add this line
        date={date}  // Add this line
        tripType={tripType}  // Add this line
        options={options}  // Add this line
      />
      {/* Conditionally render Recents component only if there are recent searches */}
      {recentSearches.length > 0 && (
        <Recents recentSearches={recentSearches} onSearch={handleRecentSearch} />
      )}
      <HomeScreenContent/>
    </>
  );
};

// Define JourneysScreen with proper types
const JourneysScreen: React.FC<JourneysScreenProps> = ({
  journeys,
  loading,
  hasError,
  errorMessage,
  earlierThan,
  laterThan,
  handleFetchJourneys,
  setOrigin,
  setDestination,
  setOriginName,
  setDestinationName,
  setDate,
  setTripType,
  setOptions,
  originId,      // Pass these values to SearchControls
  destinationId, // Add this line
  originName,  // Add this line
  destinationName, // Add this line
  date,        // Add this line
  tripType,    // Add this line
  options,     // Add this line
}) => (
  <>
    <SearchControls
      setOrigin={setOrigin}
      setDestination={setDestination}
      setOriginName={setOriginName}  // Pass the setter to SearchControls
      setDestinationName={setDestinationName}  // Pass the setter to SearchControls
      setDate={setDate}
      setTripType={setTripType}
      setOptions={setOptions}
      fetchJourneys={handleFetchJourneys}
      screen='journeys'
      originId={originId}  // Pass the current values
      destinationId={destinationId}  // Add this line
      originName={originName}  // Add this line
      destinationName={destinationName}  // Add this line
      date={date}  // Add this line
      tripType={tripType}  // Add this line
      options={options}  // Add this line
    />
    {loading && <Message type="loading" message="" />}
    {!hasError && (
      <JourneyList
        journeys={journeys}
        earlierThan={earlierThan}
        laterThan={laterThan}
        loading={loading}
        fetchJourneys={handleFetchJourneys}
        originName={originName}  // Add this line
        destinationName={destinationName}  // Add this line
        date={date}  // Add this line
        tripType={tripType}  // Add this line
      />
    )}
    {hasError && (
      <Message
        type="error"
        message={`Error message: ${errorMessage}` || 'An error occurred. Please try again later.'}
      />
    )}
  </>
);

const JourneyDetailWrapper: React.FC<{ journeys: MappedJourney[] }> = ({ journeys }) => {
  const { id } = useParams<{ id: string }>();
  const journey = journeys.find((j) => j.id === id);

  if (!journey) {
    return <Message
      type="timeout"
      message=''
    />;
  }

  return <JourneyDetailsScreen journey={journey} />;
};


const App = () => {
  const [loading, setLoading] = useState(false);
  const [hasError, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>(''); 
  const [originId, setOrigin] = useState<string>('');
  const [destinationId, setDestination] = useState<string>('');
  const [originName, setOriginName] = useState<string>('');
  const [destinationName, setDestinationName] = useState<string>(''); 
  const [date, setDate] = useState<string>(() => {
    // Try to get stored date from localStorage
    const storedDate = localStorage.getItem('localDate');
    if (storedDate) {
      return storedDate;
    }
    return ''; // Empty string indicates no date set
  });
  const [tripType, setTripType] = useState<'departure' | 'arrival'>(() => {
    return (localStorage.getItem('localTripType') as 'departure' | 'arrival') || 'departure';
  });
  const [options, setOptions] = useState<Record<string, string>>({});

  const [earlierThan, setEarlierThan] = useState<string | null>(null);
  const [laterThan, setLaterThan] = useState<string | null>(null);
  const [journeys, setJourneys] = useState<MappedJourney[]>([]);
  const [recentSearches, setRecentSearches] = useState<any[]>([]); // State to hold recent searches

  useEffect(() => {
    const storedJourneys = JSON.parse(localStorage.getItem('journeys') || '[]');
    if (storedJourneys.length > 0) {
      setJourneys(storedJourneys);
    }

    const storedEarlierThan = JSON.parse(localStorage.getItem('earlierThan') || '[]');
    if (storedEarlierThan.length > 0) {
      setEarlierThan(storedEarlierThan);
    }

    const storedLaterThan = JSON.parse(localStorage.getItem('laterThan') || '[]');
    if (storedLaterThan.length > 0) {
      setLaterThan(storedLaterThan);
    }
    
    const storedOrigin = localStorage.getItem('originId') || '';
    const storedDestination = localStorage.getItem('destinationId') || '';
  
    setOrigin(storedOrigin);
    setDestination(storedDestination);
  
    const options = Object.keys(localStorage).reduce((acc, key) => {
      if (key.startsWith('option_')) {
        const optionKey = key.substring('option_'.length);
        acc[optionKey] = localStorage.getItem(key) || '';
      }
      return acc;
    }, {} as Record<string, string>);
    
    setOptions(options);

    // Load recent searches from localStorage
    const storedSearches = JSON.parse(localStorage.getItem('recentSearches') || '[]');
    setRecentSearches(storedSearches);
  }, []);

  const saveSearchParams = (originId: string, originName: string, destinationId: string, destinationName: string, date: string, tripType: string, options: Record<string, string>) => {
    const searchDate = date || new Date().toISOString();
    
    // Create a search record
    const searchRecord = { originId, originName, destinationId, destinationName, date: searchDate, tripType, options, timestamp: new Date().toISOString() };
  
    // Get existing searches
    const existingSearches = JSON.parse(localStorage.getItem('recentSearches') || '[]');
  
    // Keep more items in storage (e.g., last 10) to ensure we can get 5 unique combinations
    const updatedSearches = existingSearches.slice(-10);
    
    // Add the new search record
    updatedSearches.push(searchRecord);
  
    // Save updated searches back to localStorage
    localStorage.setItem('recentSearches', JSON.stringify(updatedSearches));
  
    // Update the state
    setRecentSearches(updatedSearches);
  };

  const handleFetchJourneys = (type: 'earlier' | 'later' | 'initial') => {
    // Clear the error state before starting a new search
    setError(false);

    if (type === 'initial') {
      // For initial searches, if no date is set, use current datetime
      const searchDate = date || new Date().toISOString();
      
      // Save the search parameters in localStorage
      saveSearchParams(originId, originName, destinationId, destinationName, searchDate, tripType, options);

      // Clear the journeys only for initial search
      setJourneys([]); // This should clear the previous journeys
    }
    
    fetchJourneys({
      type,
      originId,
      destinationId,
      date: date || new Date().toISOString(),
      tripType,
      options,
      earlierThan,
      laterThan,
      existingJourneys: type === 'initial' ? [] : journeys,
      onJourneysFetched: (fetchedJourneys, newEarlierThan, newLaterThan) => {
        setJourneys(fetchedJourneys);
        
        // Update earlierThan or laterThan based on the type of fetch
        if (type === 'earlier' && newEarlierThan !== earlierThan) {
          setEarlierThan(newEarlierThan);
        } else if (type === 'later' && newLaterThan !== laterThan) {
          setLaterThan(newLaterThan);
        } else if (type !== 'earlier' && type !== 'later') {
          // For the initial or non-paginated fetch, update both earlierThan and laterThan
          setEarlierThan(newEarlierThan);
          setLaterThan(newLaterThan);
        }
      },
      onError: (message) => {
        setError(true);
        setErrorMessage(message);
      },
      onLoadingChange: setLoading,
    });
  };



  return (
    <Router>
    <Container>
      <Header/>
      <Routes>
        <Route
          path="/"
          element={
            <HomeScreen
              setOrigin={setOrigin}
              setDestination={setDestination}
              setOriginName={setOriginName}  // Add this line
              setDestinationName={setDestinationName}  // Add this line
              setDate={setDate}
              setTripType={setTripType}
              setOptions={setOptions}
              handleFetchJourneys={handleFetchJourneys}
              screen="home" // Add screen prop here
              recentSearches={recentSearches} // Pass recentSearches to HomeScreen
              originId={originId}  // Add this line
              destinationId={destinationId}  // Add this line
              originName={originName}  // Add this line
              destinationName={destinationName}  // Add this line
              date={date}  // Add this line
              tripType={tripType}  // Add this line
              options={options}  // Add this line
            />
          }
        />
        <Route
          path="/journeys"
          element={
            <JourneysScreen
              journeys={journeys}
              loading={loading}
              hasError={hasError}
              errorMessage={errorMessage}
              earlierThan={earlierThan}
              laterThan={laterThan}
              handleFetchJourneys={handleFetchJourneys}
              setOrigin={setOrigin}
              setDestination={setDestination}
              setOriginName={setOriginName}  // Add this line
              setDestinationName={setDestinationName}  // Add this line
              setDate={setDate}
              setTripType={setTripType}
              setOptions={setOptions}
              screen='journeys'
              recentSearches={recentSearches}
              originId={originId}  // Add this line
              destinationId={destinationId}  // Add this line
              originName={originName}  // Add this line
              destinationName={destinationName}  // Add this line
              date={date}  // Add this line
              tripType={tripType}  // Add this line
              options={options}  // Add this line
            />
          }
        />

        <Route path="/journey/:id" element={<JourneyDetailWrapper journeys={journeys} />} />

        <Route path="/train-statistics" element={<TrainStatisticsScreen />} />

        <Route path="/transfer-statistics" element={<TransferStatisticsScreen />} />

        <Route path="/data" element={<APIPage />} />

        <Route path="/contact" element={<ContactPage />} />

        <Route path="/privacy" element={<PrivacyPolicyPage />} />

        <Route path="/terms" element={<TermsConditionsPage />} />

        <Route path="/2024wrapped" element={<WrappedPage />} />

      </Routes>
      <FooterSection />
    </Container>
    </Router>
  );
};

export default App;
