import styled from "styled-components";
import { colors } from "../../utils/colors";
import * as TypographyStyles from '../TypographyStyles';
import { useEffect } from "react";


const PrivacyContainer = styled('div')`
  display: flex;
  flex-direction: column;
  padding: 40px 20px;
  align-items: flex-start;
  gap: 24px;
  background-color: ${colors.grey200}; // Light background for readability
    @media (min-width: 768px) {
    padding: 64px 64px;  // Increased horizontal padding for desktop
  }
`;

const Section = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const List = styled('ul')`
  padding-left: 20px;  /* Indent the list */
  list-style-type: disc;  /* Set bullet point style */
`;

const ListItem = styled('li')`
  margin-bottom: 10px;  /* Add space between items */
  color: ${colors.grey800};  /* Set text color */
`;

const PrivacyPolicyPage = () => {
  // Scroll to top on component mount
  useEffect(() => {
      window.scrollTo(0, 0); // Scroll to the top of the page
  }, []);

    return (
      <PrivacyContainer>
        <TypographyStyles.PageTitle color={colors.keyDarker}>Privacy Policy</TypographyStyles.PageTitle>
        
        <Section>
          <TypographyStyles.PageSubtitleSemiBold color={colors.keyDarkest}>
            Last updated: 9/10/2024
          </TypographyStyles.PageSubtitleSemiBold>
          <TypographyStyles.BodyLRegular color={colors.grey800}>
            Welcome to Chuuchuu! At Chuuchuu, we are committed to protecting your privacy. This Privacy Policy explains how we handle your information when you use our website (“App”). By using Chuuchuu, you agree to the terms of this Privacy Policy.
          </TypographyStyles.BodyLRegular>
        </Section>
  
        <Section>
          <TypographyStyles.PageSubtitleSemiBold color={colors.keyDarkest}>
            1. Information We Do Not Collect
          </TypographyStyles.PageSubtitleSemiBold>
          <TypographyStyles.BodyLRegular color={colors.grey800}>
            Chuuchuu does not collect, store, or process any personal information. We do not request, access, or store your name, email address, contact details, or any other personal identifiers.
          </TypographyStyles.BodyLRegular>
        </Section>
  
        <Section>
            <TypographyStyles.PageSubtitleSemiBold color={colors.keyDarkest}>
                2. Information Stored Locally on Your Device
            </TypographyStyles.PageSubtitleSemiBold>
            <TypographyStyles.BodyLRegular color={colors.grey800}>
                Although we do not collect personal data, Chuuchuu may store certain settings and preferences in your device’s local storage for the purpose of enhancing your user experience. The information stored locally is used solely to provide the following functionalities:
            </TypographyStyles.BodyLRegular>
            <List>
                <ListItem>
                <strong>Remembering Locations:</strong> Chuuchuu may store your previously selected or viewed locations, so you can easily access them when you return to the app.
                </ListItem>
                <ListItem>
                <strong>Options and Settings:</strong> Chuuchuu may store your preferred options, such as language, display settings, and other app functionalities, to ensure your personalized experience is maintained between sessions.
                </ListItem>
            </List>
            <TypographyStyles.BodyLRegular color={colors.grey800}>
                This data is stored locally on your device and is not transmitted to our servers or any third-party services.
            </TypographyStyles.BodyLRegular>
        </Section>
  
        <Section>
          <TypographyStyles.PageSubtitleSemiBold color={colors.keyDarkest}>
            3. Third-Party Services
          </TypographyStyles.PageSubtitleSemiBold>
          <TypographyStyles.BodyLRegular color={colors.grey800}>
            Chuuchuu does not share, sell, or disclose any information to third-party services. Since no personal data is collected, no third parties have access to any of your information.
          </TypographyStyles.BodyLRegular>
        </Section>
  
        <Section>
          <TypographyStyles.PageSubtitleSemiBold color={colors.keyDarkest}>
            4. Data Security
          </TypographyStyles.PageSubtitleSemiBold>
          <TypographyStyles.BodyLRegular color={colors.grey800}>
            Although we do not collect personal information, we take reasonable precautions to ensure that the data stored locally on your device, such as settings and preferences, is protected. However, it is your responsibility to secure your device and manage its security settings.
          </TypographyStyles.BodyLRegular>
        </Section>
  
        <Section>
          <TypographyStyles.PageSubtitleSemiBold color={colors.keyDarkest}>
            5. Changes to This Privacy Policy
          </TypographyStyles.PageSubtitleSemiBold>
          <TypographyStyles.BodyLRegular color={colors.grey800}>
            We may update this Privacy Policy from time to time. If we make material changes to this policy, we will notify you within the App or by other means before the changes take effect. Continued use of the App after such changes constitutes your acceptance of the updated Privacy Policy.
          </TypographyStyles.BodyLRegular>
        </Section>

        <Section>
          <TypographyStyles.PageSubtitleSemiBold color={colors.keyDarkest}>
            6. Children's Privacy
          </TypographyStyles.PageSubtitleSemiBold>
          <TypographyStyles.BodyLRegular color={colors.grey800}>
            Chuuchuu is not directed at individuals under the age of 13. We do not knowingly collect or store any personal information from children. If we become aware that we have inadvertently collected personal information from a child under 13, we will take steps to delete that information.
          </TypographyStyles.BodyLRegular>
        </Section>

        <Section>
          <TypographyStyles.PageSubtitleSemiBold color={colors.keyDarkest}>
            7. Your Rights
          </TypographyStyles.PageSubtitleSemiBold>
          <TypographyStyles.BodyLRegular color={colors.grey800}>
            As no personal information is collected, stored, or processed by Chuuchuu, there are no user data rights applicable under GDPR or other data protection laws. If we begin to collect personal data in the future, we will update this Privacy Policy accordingly to inform you of your rights, including rights to access, modify, or delete your personal data.
          </TypographyStyles.BodyLRegular>
        </Section>
  
        <Section>
          <TypographyStyles.PageSubtitleSemiBold color={colors.keyDarkest}>
            8. Contact Us
          </TypographyStyles.PageSubtitleSemiBold>
          <TypographyStyles.BodyLRegular color={colors.grey800}>
            If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at:
            <br />
            <br />
            Chuuchuu
            <br />
            info@chuuchuu.com
          </TypographyStyles.BodyLRegular>
        </Section>
      </PrivacyContainer>
    );
  };
  
  export default PrivacyPolicyPage;