import styled from "styled-components"
import { colors } from "../../utils/colors";
import * as TypographyStyles from '../TypographyStyles';
import { ReactSVG } from 'react-svg';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';

import logo from '../../icons/chuuchuu-beta-logo.svg';
import menu from '../../icons/hamburgericon.svg';
import cross from '../../icons/cross.svg';


const Container = styled('div')<{ menuOpen: boolean }>`
display: flex;
padding: 12px 16px;
justify-content: space-between;
align-items: center;
background: ${colors.white};
  @media (min-width: 768px) {
    padding: 16px 64px;  // Increased horizontal padding for desktop
  }
`

const LogoWrapper = styled("div")<{ menuOpen: boolean }>`
cursor: pointer;  
& svg {
    fill: ${colors.keyDarkest};
    height: 28px; /* Set height to 14px */
    width: auto;  /* Maintain aspect ratio */
`;

const MenuButton = styled("div")<{ menuOpen: boolean }>`
  cursor: pointer;
  & svg {
    stroke: ${colors.keyDarker};
    fill: ${colors.keyDarker};
    height: 24px;
    width: auto;
  }
  @media (min-width: 768px) {
    display: none;
  }
`;

const DesktopMenu = styled("div")`
  display: none;
  gap: 24px;
  @media (min-width: 768px) {
    display: flex;
    align-items: center;
  }
`;

const MenuOverlay = styled("div")`
  position: absolute;
  top: 54px; /* Position it right below the header */
  left: 0; /* Align the menu to the left side */
  right: 0; /* Align the menu to the left side */
  display: flex;
  padding: 32px 28px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  background-color: ${colors.white}; /* Change to your preferred color */
  z-index: 100; /* Ensure it's on top of other elements */
  @media (min-width: 768px) {
    display: none;
  }
`;

const MenuItem = styled(TypographyStyles.MenuItem)`
cursor: pointer;

&:hover {
  color: ${colors.keyDarkest}; /* Change this to the color you want on hover */
}
`

const Header = () => {
  const navigate = useNavigate();
  const [menuOpen, setMenuOpen] = useState(false);

  const handleNavigation = (path: string) => {
    navigate(path);
    setMenuOpen(false);
  };

  return (
    <Container menuOpen={menuOpen}>
      <LogoWrapper menuOpen={menuOpen} onClick={() => handleNavigation('/')}> 
        <ReactSVG src={logo} /> 
      </LogoWrapper>

      <DesktopMenu>
        <MenuItem color={colors.keyDarker} onClick={() => handleNavigation('/data')}>Data</MenuItem>
        <MenuItem color={colors.keyDarker} onClick={() => handleNavigation('/contact')}>Contact</MenuItem>
        <MenuItem color={colors.keyDarker} onClick={() => handleNavigation('/2024wrapped')}>2024 Wrapped</MenuItem>
      </DesktopMenu>

      <MenuButton menuOpen={menuOpen} onClick={() => setMenuOpen(!menuOpen)}>
        <ReactSVG src={menuOpen ? cross : menu} />
      </MenuButton>
            
      {menuOpen && (
        <MenuOverlay>
          <MenuItem color={colors.keyDarker} onClick={() => handleNavigation('/data')}>Data</MenuItem>
          <MenuItem color={colors.keyDarker} onClick={() => handleNavigation('/contact')}>Contact</MenuItem>
          <MenuItem color={colors.keyDarker} onClick={() => handleNavigation('/2024wrapped')}>2024 Wrapped</MenuItem>
        </MenuOverlay>
      )}
    </Container>
  );
};
  
export default Header;