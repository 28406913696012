import React from "react";
import styled from "styled-components";
import * as TypographyStyles from '../TypographyStyles';
import { colors } from "../../utils/colors";
import { ReactSVG } from 'react-svg';

// Define styled components for the box and content
const Box = styled("div")<{ bgColor: string; borderColor: string }>`
  display: flex;
  width: 118px;
  min-height: 118px;
  padding: 10px 9px 10px 9px;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  border-radius: 12px;
  flex-shrink: 0;
  border-bottom: 2px solid ${(props) => props.borderColor};
  background: ${(props) => props.bgColor};
  box-sizing: border-box;
`;

const IconWrapper = styled("div")<{ iconColor: string }>`
  font-size: 24px;
  color: ${(props) => props.iconColor};
`;

const EmojiWrapper = styled("div")`
  font-size: 24px;
  line-height: 1;
`;

const Content = styled("div")<{ titleColor: string }>`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  align-self: stretch;
  color: ${(props) => props.titleColor};
`;

const Value = styled("div")<{ valueColor: string }>`
  color: ${(props) => props.valueColor};
`;

type IconType = {
  type: 'svg';
  path: string;
} | {
  type: 'emoji';
  symbol: string;
};

// Updated props for the KPIBox
type KPIBoxProps = {
  icon: IconType;
  title: string;
  value: string | number;
  colorCondition: (value: number) => string;
  isReliability?: boolean;
};

const KPIBox: React.FC<KPIBoxProps> = ({
  icon,
  title,
  value,
  colorCondition,
  isReliability = false,
}) => {
  const numericValue = typeof value === 'number' ? value : parseFloat(value.toString());

  // Determine the base color based on the value
  const baseColor = isNaN(numericValue) ? colors.grey600 : colorCondition(numericValue);

  // Predefined color mappings
  const lightVariant = {
    [colors.red]: colors.red300,
    [colors.orange]: colors.orange300,
    [colors.green]: colors.green300,
    [colors.grey600]: colors.grey300,
  };

  const darkVariant = {
    [colors.red]: colors.red600,
    [colors.orange]: colors.orange600,
    [colors.green]: colors.green600,
    [colors.grey600]: colors.grey800,
  };

  // Determine colors based on whether the KPI is reliability or not
  const bgColor = isReliability ? baseColor : lightVariant[baseColor] || colors.grey300;
  const iconColor = isReliability ? colors.white : baseColor;
  const titleColor = isReliability ? colors.white : darkVariant[baseColor];
  const valueColor = isReliability ? colors.white : darkVariant[baseColor] || colors.grey800;
  const borderColor = isReliability ? darkVariant[baseColor] : baseColor || colors.grey800;

  return (
    <Box bgColor={bgColor} borderColor={borderColor}>
      {icon.type === 'svg' ? (
        <IconWrapper iconColor={iconColor}>
          <ReactSVG
            src={icon.path}
            beforeInjection={(svg) => {
              svg.setAttribute('fill', iconColor);
            }}
          />
        </IconWrapper>
      ) : (
        <EmojiWrapper>
          {icon.symbol}
        </EmojiWrapper>
      )}
      <Content titleColor={titleColor}>
        <TypographyStyles.KPITitleLarge>
          {title}
        </TypographyStyles.KPITitleLarge>
        <Value valueColor={valueColor}>
          <TypographyStyles.KPIValueLarge>
            {value}
          </TypographyStyles.KPIValueLarge>
        </Value>
      </Content>
    </Box>
  );
};

export default KPIBox;