// KPIsContainer.tsx
import React from "react";
import styled from "styled-components";
import { Journey } from "../../utils/mapJourneysData";
import KPIBox from "./KPI";
import { getColor } from "../../utils/colorFormatter";
import happytrain from '../../icons/chuuchuu-icon.svg';
import neutraltrain from '../../icons/chuuchuu-neutral.svg';
import sadtrain from '../../icons/chuuchuu-sad.svg';
import hourglass from '../../icons/hourglass.svg';
import cancel from '../../icons/cancel.svg';
import brokenlink from '../../icons/brokenlink.svg';
import { StyledTooltip } from '../TooltipStyles';


const Container = styled('div')`
display: flex;
align-items: flex-start;
align-content: flex-start;
gap: 16px;
align-self: stretch;
flex-wrap: wrap;
`;

type KPIsContainerProps = {
  journey: Journey;
};

const KPIsContainer: React.FC<KPIsContainerProps> = ({ journey }) => {
  // Extract, round, and default KPIs to null if unavailable
  const reliability = journey.reliability?.reliabilityScore != null
    ? Math.round(journey.reliability.reliabilityScore)
    : null;

  const averageDelay = journey.journeyDelayStats?.averageDelayAtDest != null
    ? Math.round(journey.journeyDelayStats.averageDelayAtDest)
    : null;

  const cancellationRate = journey.journeyDelayStats?.journeyCancellationRate != null
    ? Math.round(journey.journeyDelayStats.journeyCancellationRate)
    : null;

    const missedTransfers = journey.transferStats?.length
    ? (() => {
        // Extract and filter valid percentageMissedConnections values
        const validConnections = journey.transferStats
          .map((stats) => stats.percentageMissedConnections)
          .filter((value) => value != null); // Remove null and undefined values
  
        // Return the rounded maximum if there are valid values, else null
        return validConnections.length > 0
          ? Math.round(Math.max(...validConnections))
          : null;
      })()
    : null;

  // Define color conditions using the existing colorFormatter
  const reliabilityColor = (value: number) => getColor(value, 'chuuchuuScore');
  const delayColor = (value: number) => getColor(value, 'delayInMinutes');
  const cancellationColor = (value: number) => getColor(value, 'cancellationPercentage');
  const transferColor = (value: number) => getColor(value, 'missedTransfersPercentage');

  // Function to choose the correct train icon based on reliability value
  const getReliabilityIcon = (value: number) => {
    if (value === -1) return neutraltrain;
    if (value >= 75) return happytrain;
    if (value >= 50) return neutraltrain;
    return sadtrain;
  };

  return (
    <Container>
      {reliability !== null && (
        <div data-tooltip-id="reliability-tooltip">
          <KPIBox
            icon={{ type: 'svg', path: getReliabilityIcon(reliability)}} // Dynamically choose icon based on reliability value
            title="chuuchuu score™"
            value={`${reliability}`}
            colorCondition={reliabilityColor}
            isReliability={true}
          />
          <StyledTooltip id="reliability-tooltip" place="top" className="tooltip-styled">
          % chance that this journey will happen without any delays, cancellations, or missed transfers
          </StyledTooltip>
        </div>
      )}
      {averageDelay !== null && (
        <div data-tooltip-id="average-delay-tooltip">
          <KPIBox
            icon={{ type: 'svg', path: hourglass}}
            title="average delay"
            value={`${averageDelay >= 0 ? `+${averageDelay}` : `${averageDelay}`}m`}
            colorCondition={delayColor}
          />
          <StyledTooltip id="average-delay-tooltip" place="top" className="tooltip-styled">
            Average delay at the destination
          </StyledTooltip>
        </div>
      )}
      {cancellationRate !== null && (
        <div data-tooltip-id="cancellation-rate-tooltip"
        >
          <KPIBox
            icon={{ type: 'svg', path: cancel}}
            title="trains cancelled"
            value={`${cancellationRate}%`}
            colorCondition={cancellationColor}
          />
          <StyledTooltip id="cancellation-rate-tooltip" place="top" className="tooltip-styled">
            Chance of cancelled trains across the journey
          </StyledTooltip>
        </div>
      )}
      {missedTransfers !== null && (
        <div data-tooltip-id="missed-transfers-tooltip">
          <KPIBox
            icon={{ type: 'svg', path: brokenlink}}
            title="missed transfers"
            value={`${missedTransfers}%`}
            colorCondition={transferColor}
          />
          <StyledTooltip id="missed-transfers-tooltip" place="top" className="tooltip-styled">
            Missed transfers occur when the connecting train departed at or before the time of arrival of the incoming train
          </StyledTooltip>
        </div>
      )}
    </Container>
  );
};

export default KPIsContainer;