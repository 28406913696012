// TypographyStyles.tsx
import styled from 'styled-components';

const Typography = styled('span')<{ color?: string; weight?: number; size?: number }>`
  color: ${props => props.color || 'inherit'};
  font-family: 'DRPublik';
  font-size: ${props => props.size ? `${props.size}px` : '16px'};
  font-style: normal;
  font-weight: ${props => props.weight || 400};
  line-height: normal;
  margin: 0;
`

// KPI value large
export const KPIValueLarge = styled(Typography)`
  font-size: 36px;
  font-weight: 600;
`;

// KPI value small
export const KPIValueSmall = styled(Typography)`
  font-size: 24px;
  font-weight: 600;
`;

// KPI title
export const KPITitleLarge = styled(Typography)`
  font-size: 16px;
  font-weight: 600;
`;

// KPI title
export const KPITitle = styled(Typography)`
  font-size: 12px;
  font-weight: 600;
`;

// Page title
export const PageTitle = styled(Typography)`
  font-size: 32px;
  font-weight: 700;
`;

// Page title semibold
export const PageTitleSemiBold = styled(Typography)`
  font-size: 32px;
  font-weight: 600;
`;

// Page subtitle
export const PageSubtitle = styled(Typography)`
  font-size: 24px;
  font-weight: 400;
`;

// Page subtitle semibold
export const PageSubtitleSemiBold = styled(Typography)`
  font-size: 24px;
  font-weight: 600;
`;

// MenuItem
export const MenuItem = styled(Typography)`
  font-size: 20px;
  font-weight: 400;
`;

// Label XXL
export const LabelXXL = styled(Typography)`
  font-size: 20px;
  font-weight: 700;
`;

// Label XL
export const LabelXL = styled(Typography)`
  font-size: 18px;
  font-weight: 600;
`;

// Body XL Bold
export const BodyXLBold = styled(Typography)`
  font-size: 20px;
  font-weight: 700;
`;

// Body XL Semibold
export const BodyXLSemibold = styled(Typography)`
  font-size: 20px;
  font-weight: 600;
`;

// Body XL Regular
export const BodyXLRegular = styled(Typography)`
  font-size: 20px;
  font-weight: 400;
`;

// Body L Bold
export const BodyLBold = styled(Typography)`
  font-size: 16px;
  font-weight: 700;
`;

// Body L Semibold
export const BodyLSemibold = styled(Typography)`
  font-size: 16px;
  font-weight: 600;
`;

// Body L Regular
export const BodyLRegular = styled(Typography)`
  font-size: 16px;
  font-weight: 400;
`;

// Body M Bold
export const BodyMBold = styled(Typography)`
  font-size: 14px;
  font-weight: 700;
`;

// Body M Semibold
export const BodyMSemibold = styled(Typography)`
  font-size: 14px;
  font-weight: 600;
`;

// Body M Regular
export const BodyMRegular = styled(Typography)`
  font-size: 14px;
  font-weight: 400;
`;

// Body S Bold
export const BodySBold = styled(Typography)`
  font-size: 12px;
  font-weight: 700;
`;

// Body S Semibold
export const BodySSemibold = styled(Typography)`
  font-size: 12px;
  font-weight: 600;
`;

// Body S Regular
export const BodySRegular = styled(Typography)`
  font-size: 12px;
  font-weight: 400;
`;

// Body XS Bold
export const BodyXSBold = styled(Typography)`
  font-size: 10px;
  font-weight: 700;
`;

// Body XS Semibold
export const BodyXSSemibold = styled(Typography)`
  font-size: 10px;
  font-weight: 600;
`;

// Body XS Regular
export const BodyXSRegular = styled(Typography)`
  font-size: 10px;
  font-weight: 400;
`;

// Button text medium
export const ButtonTextMedium = styled(Typography)`
  font-size: 24px;
  font-weight: 600;
`;

// Body XS Regular
export const ButtonTextLarge = styled(Typography)`
  font-size: 28px;
  font-weight: 600;
`;