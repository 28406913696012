import styled from "styled-components"
import { createGlobalStyle } from "styled-components";
import Slider from 'react-slick';
import { colors } from "../../utils/colors";

import PromoCard from "./PromoCard";


const Container = styled('div')`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
gap: 8px;
width: 100%; /* Ensure the container takes full width */
margin-bottom: 28px;
`


const GlobalStyle = createGlobalStyle`
  .slick-dots {
    bottom: -35px; /* Adjust to position dots inside the carousel */
    display: block !important; /* Ensure the dots are visible */
    width: 100%;
    li {
      margin: 0 0px;
    }
    button:before {
      color: ${colors.keyHover} !important; /* Override with !important */
      font-size: 8px !important; /* Override the font size */
      opacity: 1 !important; /* Ensure dots are fully opaque */
    }
    .slick-active button:before {
      color: ${colors.keyDarker} !important; /* Active dot color */
    }
  }
`;

const SliderStyled = styled(Slider)`
  width: 100%;  // Increase width to account for negative margin

  .slick-slide > div {
    padding: 0 6px;  // Add padding to the direct child of slick-slide
  }
`;

const Carousel = () => {
    const promoCards = [
      {
        imageUrl: "/images/KPIs.png",
        title: "No more surprises",
        body: "chuuchuu uses historical data to help you find train journeys with the lowest chance of disruption.",
      },
      {
        imageUrl: "/images/Scores.png",
        title: "Train reliability at a glance",
        body: "See which journeys have the highest reliability with chuuchuu Score™.",
      },
      {
        imageUrl: "/images/Transfer.png",
        title: "Never miss a train again",
        body: "See how much time you really have when connecting to another train, and the chance you might miss it.",
      },
      {
        imageUrl: "/images/Alternatives.png",
        title: "Build your ideal journey",
        body: "Transfer time too tight? Arrive earlier or depart later at the transfer station with one click.",
      },
      {
        imageUrl: "/images/Chart.png",
        title: "Dive deep",
        body: "See last month’s delays and transfer times. Find trends. Become a train wizard.",
      },
      // Add more promo cards as needed
    ];
  
    const settings = {
      dots: true,
      infinite: true,
      autoplaySpeed: 6000,
      slidesToShow: 3,  // Add base value
      slidesToScroll: 3,
      speed: 500,
      centerMode: true,
      centerPadding: '18px',
      arrows: false, // Disable the arrow buttons
      autoplay: true,
      responsive: [
        {
          breakpoint: 768,  // Triggers when screen width < 768px
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 1200,  // Default for larger screens
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2
          }
        },
        {
          breakpoint: 10000,  // Default for larger screens
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3
          }
        }
      ]
    };
  
    return (
      <>
      <GlobalStyle /> {/* Ensure the global styles are applied */}
        <Container>
            <SliderStyled {...settings}>
                {promoCards.map((card, index) => (
                <PromoCard
                    key={index}
                    imageUrl={card.imageUrl}
                    title={card.title}
                    body={card.body}
                />
                ))}
            </SliderStyled>
        </Container>
      </>
    );
  };
  
  export default Carousel;