import React from 'react';
import { ReactComponent as ChevronLeftIcon } from '../../icons/chevron-left.svg';
import { ReactComponent as ChevronRightIcon } from '../../icons/chevron-right.svg';
import { ReactComponent as DoubleChevronLeftIcon } from '../../icons/doublechevron_left.svg';
import { ReactComponent as DoubleChevronRightIcon } from '../../icons/doublechevron_right.svg';
import styled from "styled-components";
import { colors } from "../../utils/colors";


const ArrowButton = styled('div')<{ disabled?: boolean }>`
  display: flex;
  width: 34px;
  height: 34px;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 1px solid ${props => props.disabled ? colors.grey300 : colors.grey600};
  cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
  background-color: ${props => props.disabled ? 'transparent' : 'transparent'}

  svg {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
  }

  svg path {
    fill: ${props => props.disabled ? colors.grey300 : colors.grey600};
  }

  &:hover {
    background-color: ${props => props.disabled ? 'transparent' : colors.grey300};
    border-color: ${props => props.disabled ? colors.grey300 : colors.grey600};

    svg path {
      fill: ${props => props.disabled ? colors.grey300 : colors.grey600};
    }
  }

  &:active {
    background-color: ${props => props.disabled ? 'transparent' : colors.grey600};
    
    svg path {
      fill: var(--Key, ${colors.white});
    }
  }
`;

type Props = {
  onClick: () => void;
  disabled: boolean;
} 

export const PrevButton: React.FC<Props> = ({ onClick, disabled }) => {
  return (
    <ArrowButton onClick={disabled ? undefined : onClick} disabled={disabled}>
      <ChevronLeftIcon width={14} height={14}/>
    </ArrowButton>
  );
}


export const NextButton: React.FC<Props> = ({ onClick, disabled }) => {
  return (
    <ArrowButton onClick={disabled ? undefined : onClick} disabled={disabled}>
      <ChevronRightIcon width={14} height={14}/>
    </ArrowButton>
  );
}

export const FirstButton: React.FC<Props> = ({ onClick, disabled }) => {
  return (
    <ArrowButton onClick={disabled ? undefined : onClick} disabled={disabled}>
      <DoubleChevronLeftIcon width={14} height={14}/>
    </ArrowButton>
  );
}


export const LastButton: React.FC<Props> = ({ onClick, disabled }) => {
  return (
    <ArrowButton onClick={disabled ? undefined : onClick} disabled={disabled}>
      <DoubleChevronRightIcon width={14} height={14}/>
    </ArrowButton>
  );
}

