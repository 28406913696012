import styled from "styled-components"

import Hero from "./Hero";
import API from "./Data";

const Container = styled('div')`
display: flex;
flex-direction: column;
align-items: center;
`

const HomeScreenContent = () => {
    return (
      <Container>
        <Hero/>
        <API/>
      </Container>
    );
  };
  
  export default HomeScreenContent;