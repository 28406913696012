import styled from "styled-components";
import * as TypographyStyles from './TypographyStyles';
import { colors } from "../utils/colors";
import { ButtonS } from "./Controls/Buttons";

const Container = styled('div')`
    display: flex;
    padding: 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    background: ${colors.keyBackground};
    border-radius: 12px;
    border-bottom: 2px solid ${colors.keyHover};
    @media (min-width: 768px) {
        max-width: 400px;
    }
`

const CardBody = styled('div')`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 14px;
`

const FeedbackButton = styled.a`
    text-decoration: none;
`;

interface FeedbackCardProps {
    source: string; 
}

const FeedbackCard: React.FC<FeedbackCardProps> = ({ source }) => {
  const mailtoLink = `mailto:feedback@chuuchuu.com?subject=${encodeURIComponent(source)}&body=${encodeURIComponent("To help us improve chuuchuu, follow these instructions:\n\n1. Missing something: describe what you are missing and why\n2. Mistakes or bugs found: describe what you were trying to do, what happened and what you expected to happen instead\n3. Ideas for improvements: tell us what you think would make chuuchuu better and why\n\nIf possible, include a screenshot so we have a better idea of what you're talking about!\n\nThanks for helping us!\n\n------------Your message below------------")}`;

  return (
    <Container>
        <CardBody>
            <TypographyStyles.PageSubtitleSemiBold color={colors.keyDarkest}>chuuchuu is still in beta</TypographyStyles.PageSubtitleSemiBold>
            <TypographyStyles.BodyLRegular color={colors.keyDarkest}>Missing something? Found a mistake? Got an idea how to improve? Tell us!</TypographyStyles.BodyLRegular>
        </CardBody>
        <FeedbackButton href={mailtoLink}>
            <ButtonS>Give feedback</ButtonS>
        </FeedbackButton>
    </Container>
  )
}

export default FeedbackCard;