import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { colors } from '../../utils/colors';
import { useLocation } from 'react-router-dom';
import PageHeader from '../PageHeader';
import { LineStats } from './Types/lineStats';
import { DelayHistory } from './Types/delayHistory';
import { DropdownM } from '../Controls/Dropdowns';
import AvgDelayCard from './AvgDelayCard'
import DelayHistoryCard from './DelayHistoryCard';
import DistributionCard from './DelayDistributionCard';
import DelayEvolutionCard from './DelayEvolutionCard'
import FeedbackCard from '../FeedbackCard';



const Container = styled('div')`
  display: flex;
  flex-direction: column;
  padding: 24px 16px;
  gap: 32px;
  @media (min-width: 768px) {
    padding: 36px 64px;  // Increased horizontal padding for desktop
  }
`

const ChartsContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: left;
  gap: 32px;
  @media (min-width: 768px) {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
    grid-auto-rows: min-content;
    gap: 32px;
  }
  > * {
    max-width: 1200px;
  }
`

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
  padding: 24px 16px; // Add 24px padding at the top and bottom
  background: ${colors.white};
  border-bottom: 2px solid ${colors.keyHover};
  @media (min-width: 768px) {
    padding: 24px 64px;  // Increased horizontal padding for desktop
  }
`;

const DropdownContainer = styled('div')`
  display: flex;
  align-items: center;
  align-content: center;
  gap: 8px;
  align-self: stretch;
  flex-wrap: wrap;
`


// Define the LineStatisticsResponse interface as an array of LineStats objects
type LineStatisticsResponse = LineStats[];

// Define a type for delay history response if different from LineStatisticsResponse
type DelayHistoryResponse = DelayHistory[];

const TrainStatistics = () => {
    const location = useLocation(); // Initialize useLocation
    const leg = location.state?.leg;

    const [selectedOption, setSelectedOption] = useState<string>('destination');
    const [lineStatsData, setlineStatsData] = useState<LineStatisticsResponse | null>(null);
    const [delayHistoryData, setDelayHistoryData] = useState<DelayHistoryResponse | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
      if (!leg) return;
  
      const stopId = selectedOption === 'origin' ? leg.origin.id : leg.destination.id;
  
      const fetchData = async () => {
        try {
          setLoading(true);
          setError(null);
  
          // Make a single request to the Netlify function
          const response = await fetch('/.netlify/functions/getTrainStats', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              lineName: leg.line.name,
              stopId,
            }),
          });
  
          if (!response.ok) {
            throw new Error('No delay statistics available at this stop');
          }
    
          // Parse the response
          const { lineStatsData, delayHistoryData } = await response.json();
  
          // Set state with the results
          setlineStatsData(lineStatsData);
          setDelayHistoryData(delayHistoryData);
        } catch (err) {
          if (err instanceof Error) {
            setError(err.message);
          } else {
            setError('An unknown error occurred');
          }
        } finally {
          setLoading(false);
        }
      };
  
      fetchData();
    }, [leg, selectedOption]);

    const handleDropdownChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedOption(event.target.value);
    };

    // Scroll to top on component mount
    useEffect(() => {
      window.scrollTo(0, 0); // Scroll to the top of the page
    }, []);

  return (
    <>
    <HeaderContainer>
      <PageHeader
        title={`${leg.line.name} statistics`}
        subtitle={`${leg.origin.name} ➜ ${leg.destination.name}`}
        backButtonTitle="Journey details"
      />
    </HeaderContainer>
    <Container>
      <DropdownContainer>
          <DropdownM value={selectedOption} onChange={handleDropdownChange}>
              <option value="origin">Departure at {leg.origin.name}</option>
              <option value="destination">Arrival at {leg.destination.name}</option>
          </DropdownM>
      </DropdownContainer>
      {loading && <div>Loading...</div>}
      {error && <div>Error: {error}</div>}
      <ChartsContainer>
        {delayHistoryData && (
            <DistributionCard
                delayHistoryData={delayHistoryData} 
                selectedOption={selectedOption as 'origin' | 'destination'} 
            />
        )}
        {delayHistoryData && (
            <DelayEvolutionCard
                delayHistoryData={delayHistoryData} 
                selectedOption={selectedOption as 'origin' | 'destination'} 
            />
        )}
        {delayHistoryData && (
            <DelayHistoryCard 
                delayHistoryData={delayHistoryData} 
                selectedOption={selectedOption as 'origin' | 'destination'} 
            />
        )}
        {lineStatsData && (
            <AvgDelayCard data={lineStatsData} />
        )} 
      </ChartsContainer>
      <FeedbackCard source={`${leg.line.name} statistics from ${leg.origin.name} to ${leg.destination.name}`} />
    </Container>
    </>
  );
};

export default TrainStatistics;