import styled from "styled-components"
import { colors } from "../../utils/colors";
import * as TypographyStyles from '../TypographyStyles';



const Container = styled('div')`
  display: flex;
  height: 300px;
  flex-direction: column;
  align-items: center;  // Add this line
  align-self: center;   // Add this line
  border-radius: 12px;
  border-bottom: 2px solid ${colors.grey400};
  overflow: hidden; /* Ensures the image stays within the border radius */
  background-color: transparent; /* Make the background transparent */
  margin: 0px;
  width: 100%;          // Add this line
  @media (min-width: 768px) {
    height: 400px;
    max-width: 800px;
  }
`

// Image container that will take up the flexible space
const ImageContainer = styled.div`
  flex: 1 0 0; /* Allows the image to take up the remaining space */
  align-self: stretch; /* Ensures it spans the entire width */
  background-size: cover;
  background-position: center;
  width: 100%;
  background-color: transparent; /* Make the background transparent */

`;

const CardHeader = styled('div')`
display: flex;
padding: 20px 16px;
flex-direction: column;
justify-content: flex-end;
align-items: flex-start;
gap: 8px;
align-self: stretch;
background: ${colors.white};
`

// Container to hold the body and "Learn more" link inline
const BodyContainer = styled.div`
  display: inline; /* Ensures both text and link are inline */
`;

const LearnMoreLink = styled.span`
  margin-left: 6px; /* Adds some space between body and the link */
  cursor: pointer; /* Changes cursor to pointer */
  color: ${colors.key}; /* You can customize the color here */
`;

interface PromoCardProps {
    imageUrl: string;
    title: string;
    body: string;
    showLearnMore?: boolean; // Optional prop to conditionally show "Learn more"
}

const PromoCard = ({ imageUrl, title, body, showLearnMore }: PromoCardProps) => {
    return (
      <Container>
        <ImageContainer style={{ backgroundImage: `url(${imageUrl})` }} />
        <CardHeader>
            <TypographyStyles.BodyXLBold color={colors.black}>{title}</TypographyStyles.BodyXLBold>
            <BodyContainer>
                <TypographyStyles.BodyLRegular color={colors.grey800}>{body}</TypographyStyles.BodyLRegular>
                {showLearnMore && (
                <LearnMoreLink>
                    <TypographyStyles.BodyLSemibold color={colors.key}>Learn more</TypographyStyles.BodyLSemibold>
                </LearnMoreLink>
                )}
            </BodyContainer>
        </CardHeader>
      </Container>
    );
  };
  
  export default PromoCard;