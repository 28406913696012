import { colors } from "./colors"

type NumberType = 'delayInMinutes' | 'cancellationPercentage' | 'transferTimeInMinutes' | 'missedTransfersPercentage' | 'punctualityPercentage' | 'chuuchuuScore'

export const getColor = (value: number, type: NumberType): string => {
  if (type === 'delayInMinutes') {
    if (value < 0) return colors.black
    else if (value <= 4) return colors.green
    else if (value <= 14) return colors.orange
    else return colors.red
  }

  if (type === 'cancellationPercentage') {
    if (value <= 9) return colors.green
    else if (value <= 20) return colors.orange
    else return colors.red
  }

  if (type === 'transferTimeInMinutes') {
    if (value >= 10) return colors.green
    else if (value >= 5) return colors.orange
    else return colors.red
  }

  if (type === 'missedTransfersPercentage') {
    if (value <= 9) return colors.green
    else if (value <= 20) return colors.orange
    else return colors.red
  }

  if (type === 'punctualityPercentage') {
    if (value > 80) return colors.green
    else if (value >= 60) return colors.orange
    else return colors.red
  }

  if (type === 'chuuchuuScore') {
    if (value === -1) return colors.grey600;
    if (value >= 75) return colors.green
    else if (value >= 50) return colors.orange
    else return colors.red
  }

  return colors.grey800;
}