import React, { useState, useMemo } from 'react';
import styled from 'styled-components';
import { colors } from "../../../utils/colors";
import * as TypographyStyles from '../../TypographyStyles';
import {
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  useReactTable,
  type ColumnDef,
  type CellContext
} from '@tanstack/react-table';

import { TextField } from "../../Controls/TextFields";
import * as Dropdowns from '../../Controls/Dropdowns';
import { NextButton, PrevButton, FirstButton, LastButton } from '../../Controls/PrevNextButtons';
import { getColor } from '../../../utils/colorFormatter';

// Reuse the same styled components
const ContainerCard = styled('div')`
    display: flex;
    padding: 20px;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    align-self: stretch;
    border-radius: 12px;
    border-bottom: 2px solid ${colors.grey400};
    background: ${colors.white};
`

const CardHeader = styled('div')`
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 8px;
`

const CardTitle = styled('div')`
    display: flex;
    align-items: flex-start;
    gap: 4px;
`

const CardDescription = styled('div')`
    display: flex;
    align-items: flex-start;
    gap: 4px;
`

const TableContainer = styled('div')`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
`

const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const TableHead = styled.thead``;

const TableHeadRow = styled.tr``;

const TableHeadCell = styled.th`
  padding: 12px;
  text-align: left;
  font-size: 14px;
  font-weight: 600;
  color: ${colors.grey600};
  border-bottom: 1px solid ${colors.grey300};
`;

const TableBody = styled.tbody``;

const TableRow = styled.tr`
  border-bottom: 1px solid ${colors.grey300};
  &:hover {
    background-color: ${colors.grey200};
  }
`;

const TableCell = styled.td`
  padding: 12px;
  font-size: 14px;
`;

const CardFooter = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
`;

const PageInfo = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 12px;
    align-self: stretch;
`;

const PaginatorContainer = styled.div`
    display: flex;
    align-items: flex-start;
    gap: 8px;
`;

interface Station {
  stopName: string;
  avgArrivalDelay: number;
}

const StationsTable: React.FC<{ data: Station[] }> = ({ data }) => {
    const [sorting, setSorting] = useState<any[]>([]);
    const [globalFilter, setGlobalFilter] = useState('');
    const [columnFilters, setColumnFilters] = useState<any[]>([]);
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 20,
    });
  
    const columns = useMemo<ColumnDef<Station>[]>(
        () => [
          {
            header: "Station",
            accessorKey: "stopName",
          },
          {
            header: "Average delay (min)",
            accessorKey: "avgArrivalDelay",
            cell: (info: CellContext<Station, any>) => {
              const value = info.getValue();
              if (typeof value === 'number') {
                const color = getColor(value, 'delayInMinutes');
                return (
                  <TypographyStyles.BodyMRegular color={color}>
                    {value.toFixed(1)}
                  </TypographyStyles.BodyMRegular>
                );
              }
              return '';
            },
          }
        ],
        []
    );

    const table = useReactTable({
        data,
        columns,
        state: {
            sorting,
            globalFilter,
            columnFilters,
            pagination,
        },
        onPaginationChange: setPagination,
        manualPagination: false,
        onSortingChange: setSorting,
        onGlobalFilterChange: setGlobalFilter,
        onColumnFiltersChange: setColumnFilters,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
    });

    return (
        <ContainerCard>
            <CardHeader>
                <CardTitle>
                    <TypographyStyles.BodyXLSemibold color={colors.black}>Station reliability</TypographyStyles.BodyXLSemibold>
                    <TypographyStyles.BodyXLRegular color={colors.grey600}>
                        (2024)
                    </TypographyStyles.BodyXLRegular>
                </CardTitle>
                <CardDescription>
                    <TypographyStyles.BodyLRegular color={colors.grey600}>
                        Including all stations with at least 1000 recorded arrivals throughout the year. Average delay is based on arrival delays only. Data from April 2024 until December 2024.
                    </TypographyStyles.BodyLRegular>
                </CardDescription>
            </CardHeader>
            
            {/* Search and Filter Input */}
            <TextField 
                instruction=""
                dynamicPlaceholder="Filter by station name..."
                value={globalFilter ?? ''}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setGlobalFilter(e.target.value)}
                isBackgroundDark={true}
                isError={false}
                errorMessage=""
                isDisabled={false}
            />

            {/* Table */}
            <TableContainer>
                <StyledTable>
                    <TableHead>
                        {table.getHeaderGroups().map(headerGroup => (
                            <TableHeadRow key={headerGroup.id}>
                                {headerGroup.headers.map(header => (
                                    <TableHeadCell key={header.id}>
                                        <TypographyStyles.BodyLSemibold color={colors.grey600}>
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    gap: '8px',
                                                    cursor: header.column.getCanSort() ? 'pointer' : 'default',
                                                }}
                                                onClick={header.column.getToggleSortingHandler()}
                                            >
                                                {flexRender(
                                                    header.column.columnDef.header,
                                                    header.getContext()
                                                )}
                                                {header.column.getCanSort() && (
                                                    <>
                                                        {header.column.getIsSorted() === 'asc' && '↑'}
                                                        {header.column.getIsSorted() === 'desc' && '↓'}
                                                    </>
                                                )}
                                            </div>
                                        </TypographyStyles.BodyLSemibold>
                                    </TableHeadCell>
                                ))}
                            </TableHeadRow>
                        ))}
                    </TableHead>
                    <TableBody>
                        {table.getRowModel().rows.map(row => (
                            <TableRow key={row.id}>
                                {row.getVisibleCells().map((cell, index) => (
                                    <TableCell key={cell.id}>
                                        {index === 0 ? (
                                            <TypographyStyles.BodyLSemibold>
                                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                            </TypographyStyles.BodyLSemibold>
                                        ) : (
                                            <TypographyStyles.BodyLRegular>
                                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                            </TypographyStyles.BodyLRegular>
                                        )}
                                    </TableCell>
                                ))}
                            </TableRow>
                        ))}
                    </TableBody>
                </StyledTable>
            </TableContainer>

            {/* Pagination Controls */}
            <CardFooter>
                <Dropdowns.DropdownS
                    value={table.getState().pagination.pageSize}
                    onChange={e => {
                        table.setPageSize(Number(e.target.value));
                    }}
                >
                    {[10, 20, 30, 40, 50].map(pageSize => (
                        <option key={pageSize} value={pageSize}>
                            {pageSize} per page
                        </option>
                    ))}
                </Dropdowns.DropdownS>
                <PageInfo>
                    <TypographyStyles.BodyMRegular color={colors.grey600}>
                        Page {table.getState().pagination.pageIndex + 1} of{' '}
                        {table.getPageCount()}
                    </TypographyStyles.BodyMRegular>
                    <PaginatorContainer>
                        <FirstButton
                            onClick={() => table.setPageIndex(0)}
                            disabled={!table.getCanPreviousPage()}
                        />
                        <PrevButton
                            onClick={() => table.previousPage()}
                            disabled={!table.getCanPreviousPage()}
                        />
                        <NextButton
                            onClick={() => table.nextPage()}
                            disabled={!table.getCanNextPage()}
                        />
                        <LastButton
                            onClick={() => table.setPageIndex(table.getPageCount() - 1)}
                            disabled={!table.getCanNextPage()}
                        />
                    </PaginatorContainer>
                </PageInfo>
            </CardFooter>
        </ContainerCard>
    );
};

export default StationsTable;