import styled from "styled-components"
import * as TypographyStyles from '../TypographyStyles';
import { colors } from "../../utils/colors";



const Container = styled('div')`
display: flex;
padding: 40px;
justify-content: space-between;
align-items: center;
align-self: stretch;
background: ${colors.grey400};
  @media (min-width: 768px) {
    padding: 64px 64px;  // Increased horizontal padding for desktop
  }
`

const Footer = () => {
    return (
      <Container>
        <TypographyStyles.BodyXSRegular color={colors.grey800} style={{ textAlign: 'center' }}>©️ Copyright chuuchuu 2024 - Designed and developed by Arne Nys</TypographyStyles.BodyXSRegular>
      </Container>
    );
  };
  
  export default Footer;