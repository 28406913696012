import { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import * as TypographyStyles from '../TypographyStyles';
import { ReactSVG } from 'react-svg';
import mixpanel from 'mixpanel-browser';

import calendarIcon from '../../icons/calendar.svg';
import controlsIcon from '../../icons/controls.svg';
import upDownArrowsIcon from '../../icons/updownarrows.svg';
import searchIcon from '../../icons/searchicon.svg';
import { format, isToday, isTomorrow, parseISO } from 'date-fns';
import { colors } from '../../utils/colors'

// Import overlay components
import LocationPicker from './LocationPicker';
import DateTimeCard from './DateTimeCard';
import OptionsCard from './OptionsCard';
import PageHeader from '../PageHeader';

// Container for the entire component
const Container = styled.div<{ screen: 'home' | 'journeys' }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
  padding: 24px 16px; // Add 24px padding at the top and bottom
  background: ${({ screen }) =>
    screen === 'home' ? colors.keyDarkest : colors.white};  // Different backgrounds
  border-bottom: 2px solid ${({ screen }) =>
    screen === 'home' ? colors.key : colors.keyHover};    // Different borders
    @media (min-width: 768px) {
    padding: 24px 64px;  // Increased horizontal padding for desktop
  }
`;

// Container for the controls
const Controls = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  align-self: stretch;
  position: relative;  // To position the switch button absolutely within this container
`;

// Wrapper for the origin and destination buttons
const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  align-self: stretch;
  border-radius: 8px;
  overflow: hidden;
`;

// Common styles for buttons
const LocationButton = styled.button<{ screen: 'home' | 'journeys' }>`
  display: flex;
  flex-direction: column;
  padding: 11px 18px;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  background: ${({ screen }) =>
    screen === 'home'
      ? 'rgba(255, 255, 255, 0.15)' // Background for home screen
      : 'rgba(151, 71, 255, 0.10)'}; // Background for journeys screen
  border: none;
  cursor: pointer;
`;

// Specific styles for the switch button
const SwitchButton = styled.button<{ screen: 'home' | 'journeys' }>`
  display: flex;
  width: 36px;
  height: 36px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  position: absolute;
  right: 11px;
  top: 30px;
  border-radius: 100px;
  background: ${({ screen }) =>
    screen === 'home' ? colors.keyDarkest : colors.keyHover};  // Different backgrounds
  border: none;
  cursor: pointer;
`;

// Wrapper for DateTime and Options buttons
const ControlsWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
  margin-top: 12px;
`;

// Styles for DateTime button
const DateTimeButton = styled.button<{ screen: 'home' | 'journeys' }>`
  display: flex;
  padding: 12px 18px;
  align-items: center;  // Align items vertically centered
  gap: 8px;
  border-radius: 8px;
  background: ${({ screen }) =>
    screen === 'home'
      ? 'rgba(255, 255, 255, 0.15)' // Background for home screen
      : 'rgba(151, 71, 255, 0.10)'}; // Background for journeys screen
  border: none;
  cursor: pointer;
  flex: 1 1 auto;  // Makes the button grow to fill available horizontal space
`;

// Styles for Options button
const OptionsButton = styled.button<{ screen: 'home' | 'journeys' }>`
  display: flex;
  padding: 12px 18px;
  align-items: center;  // Align items vertically centered
  gap: 8px;
  border-radius: 8px;
  background: ${({ screen }) =>
    screen === 'home'
      ? 'rgba(255, 255, 255, 0.15)' // Background for home screen
      : 'rgba(151, 71, 255, 0.10)'}; // Background for journeys screen
  border: none;
  cursor: pointer;
  flex: 0 0 auto;  // Button width hugs its content
`;

const SearchButton = styled.button`
  margin-top: 24px;
  display: flex;
  padding: 17px 32px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 12px;
  border: 2px solid ${colors.keyDarker};
  background: ${colors.keyBackground};
  
  &:hover {
    border: 2px solid ${colors.key};
    background: ${colors.white};
  }
`;

// Wrapper for label and location name
const LabelWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;  // 8px gap between label and location name
  width: 100%;  // Ensures full width for text overflow handling
`;

// Typography styles for labels
const LabelText = styled(TypographyStyles.BodyXLRegular)<{ screen: 'home' | 'journeys' }>`
  color:  ${({ screen }) =>
    screen === 'home' ? colors.keyHover : colors.key};
`;

// Typography styles for location names
const LocationText = styled(TypographyStyles.BodyXLSemibold)<{ screen: 'home' | 'journeys' }>`
  color:  ${({ screen }) =>
    screen === 'home' ? colors.white : colors.keyDarkest};
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  display: -webkit-box;
`;

// Typography styles for Control button text
const ControlText = styled(TypographyStyles.BodyLRegular)<{ screen: 'home' | 'journeys' }>`
  color:  ${({ screen }) =>
    screen === 'home' ? colors.keyBackground : colors.keyDarker};
  text-align: center;  // Center-align text in the button
`;

// Container for time and date text in ControlButton
const DateTimeText = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;  // Gap between time and date
  width: 100%;  // Ensures the text container fills the button
`;

// Typography styles for location names
const SearchButtonText = styled(TypographyStyles.ButtonTextLarge)`
  color: ${colors.keyDarkest};
  leading-trim: both;
  text-edge: cap;
  line-height: 24px; /* 85.714% */
`;

// Icon styles for control buttons
const ControlIcon = styled.div<{ screen: 'home' | 'journeys' }>`
  width: 20px;
  height: 19px;
  svg {
    width: 100%;
    height: 100%;
    fill:  ${({ screen }) =>
    screen === 'home' ? colors.keyHover : colors.key};
    display: block; 
  }
`;

// Icon styles for switch button
const SwitchIcon = styled.div<{ screen: 'home' | 'journeys' }>`
  width: 16px;
  height: 16px;
  svg {
    width: 100%;
    height: 100%;
    fill:  ${({ screen }) =>
    screen === 'home' ? colors.white : colors.keyDarker};
    display: block; 
  }
`;

// Icon styles for search button
const SearchIcon = styled.div`
  height: 24px;
  svg {
    width: 100%;
    height: 100%;
    fill: ${colors.keyDarkest};
    display: block; 
  }
`;

// Overlay styling
const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

interface SearchControlsProps {
  setOrigin: (id: string) => void;
  setDestination: (id: string) => void;
  setDate: (date: string) => void;
  setOriginName: React.Dispatch<React.SetStateAction<string>>; 
  setDestinationName: React.Dispatch<React.SetStateAction<string>>; 
  setTripType: (type: 'departure' | 'arrival') => void;
  setOptions: (options: Record<string, string>) => void;
  fetchJourneys: (type: 'earlier' | 'later' | 'initial') => void;
  screen: 'home' | 'journeys';
  originId: string;  // Add this line
  destinationId: string;  // Add this line
  originName: string;  // Add this line
  destinationName: string;  // Add this line
  date: string;  // Add this line
  tripType: 'departure' | 'arrival';  // Add this line
  options: Record<string, string>;  // Add this line
}

const defaultOptions: Record<string, string> = {
  transfers: '-1',
  transferTime: '0',
  accessibility: 'none',
  bike: 'false',
  longdistance: 'true',
  regional: 'true',
  suburban: 'true',
  local: 'true',
};

// Define the type for location details
interface Location {
  id: string;
  name: string;
}

const SearchControls = ({
  setOrigin, 
  setDestination, 
  setOriginName, 
  setDestinationName, 
  setDate, 
  setTripType, 
  setOptions, 
  fetchJourneys, 
  screen, 
  originId,
  originName,  // controlled prop
  destinationId,
  destinationName,  // controlled prop
  date,  // controlled prop
  tripType,  // controlled prop
  options  // controlled prop
}: SearchControlsProps) => {  // Initialize state for origin and destination as objects
  
  const loadFromLocalStorage = useCallback(() => {
    const savedOrigin = localStorage.getItem('origin');
    const savedDestination = localStorage.getItem('destination');
    const savedOptions = localStorage.getItem('journeyOptions');

    // Only load from localStorage if we haven't already populated values in the component
    if (savedOrigin && !originId) {
      const parsed = JSON.parse(savedOrigin);
      setOrigin(parsed.id);
      setOriginName(parsed.name);
    }

    if (savedDestination && !destinationId) {
      const parsed = JSON.parse(savedDestination);
      setDestination(parsed.id);
      setDestinationName(parsed.name);
    }

    if (savedOptions && Object.keys(options).length === 0) {
      setOptions(JSON.parse(savedOptions));
    } else {
      setOptions(defaultOptions);  // Ensure default options are used if none are found
    }
}, [setOrigin, setOriginName, setDestination, setDestinationName, setOptions, originId, destinationId, options]);
  
  const [isLocationPickerOpen, setLocationPickerOpen] = useState(false);
  const [locationType, setLocationType] = useState<'from' | 'to'>('from');

  const [isDateTimeCardOpen, setDateTimeCardOpen] = useState(false);

  const [isOptionsCardOpen, setOptionsCardOpen] = useState(false);
  const [localOptions, setLocalOptions] = useState<Record<string, string>>(() => {
    const savedOptions = localStorage.getItem('journeyOptions');
    return savedOptions ? JSON.parse(savedOptions) : defaultOptions;
  });

  const [recentlyChosen, setRecentlyChosen] = useState<{ name: string; id: string }[]>(
    JSON.parse(localStorage.getItem('recentlyChosen') || '[]')
  );

  const [showControls, setShowControls] = useState(screen === 'home'); // Track visibility of controls

  useEffect(() => {
    // Load from localStorage only if both origin and destination are empty and options are default
    if (!originId && !destinationId) {
      loadFromLocalStorage();
    }
  }, [originId, destinationId, loadFromLocalStorage]);

  useEffect(() => {
    if (Object.keys(options).length === 0) {
      loadFromLocalStorage();
    }
  }, [options, loadFromLocalStorage]);

  useEffect(() => {
    // Save origin and destination to localStorage
    if (originId && originName) {
      localStorage.setItem('origin', JSON.stringify({ id: originId, name: originName }));
    }
    if (destinationId && destinationName) {
      localStorage.setItem('destination', JSON.stringify({ id: destinationId, name: destinationName }));
    }

      // Save date and trip type to localStorage
    if (date) {
      localStorage.setItem('localDate', date);  // Save date
    }
    if (tripType) {
      localStorage.setItem('localTripType', tripType);  // Save trip type
    }
    
    // Save options to localStorage
    if (Object.keys(options).length > 0) {
      localStorage.setItem('journeyOptions', JSON.stringify(options));
    }

    // Save recently chosen locations
    localStorage.setItem('recentlyChosen', JSON.stringify(recentlyChosen));
  }, [originId, originName, destinationId, destinationName, options, recentlyChosen, date, tripType]);

  const openOverlay = (type: 'from' | 'to') => {
    setLocationType(type);
    setLocationPickerOpen(true);
  };

  const closeOverlay = () => {
    setLocationPickerOpen(false);
    setOptionsCardOpen(false);
    setDateTimeCardOpen(false);
  };

  const handleLocationSelect = (location: Location) => {
    setRecentlyChosen((prev) => [location, ...prev].slice(0, 10));

    if (locationType === 'from') {
      setOrigin(location.id);
      setOriginName(location.name);
    } else {
      setDestination(location.id);
      setDestinationName(location.name);
    }

    closeOverlay();
  };

  const handleSwitchLocations = () => {
    setOrigin(destinationId);
    setDestination(originId);
    setOriginName(destinationName);
    setDestinationName(originName);
  };

  // Function to handle the date and time application
  const handleDateTime = (newDateTime: string, newType: 'departure' | 'arrival') => {
    setDate(newDateTime);
    setTripType(newType);
    localStorage.setItem('localDate', newDateTime);
    localStorage.setItem('localTripType', newType);
    setDateTimeCardOpen(false);
  };

  // Format the date string with proper handling for "now"
  const getFormattedDateTime = () => {
    if (!date) {
      return 'Depart now';
    }

    try {
      const parsedDate = parseISO(date);
      if (isNaN(parsedDate.getTime())) {
        return 'Depart now';
      }

      const dateStr = isToday(parsedDate) 
        ? 'today' 
        : isTomorrow(parsedDate) 
          ? 'tomorrow' 
          : format(parsedDate, 'dd/MM');
      
      const timeStr = format(parsedDate, 'HH:mm');
      return `${tripType === 'arrival' ? 'Arrive' : 'Depart'} ${dateStr} at ${timeStr}`;
    } catch {
      return 'Depart now';
    }
  };

  // Function to handle the application of options
  const handleOptions = (newOptions: Record<string, string>) => {
    setLocalOptions(newOptions);
    setOptions(newOptions);
    localStorage.setItem('journeyOptions', JSON.stringify(newOptions));
    setOptionsCardOpen(false);
  };

  // Function to handle the search button click
  const handleSearchClick = () => {

      // Track the search button click with Mixpanel
      mixpanel.track('Search Button Clicked', {
        originName,
        destinationName,
        date,
        tripType,
        options: localOptions,  // Send options in case they're changed
      });

    // Call the fetchJourneys function after setting the date
    fetchJourneys('initial');
  };

  const handleToggleClick = () => {
    setShowControls((prev) => !prev); // Toggle the visibility of controls
  };

  return (
    <Container screen={screen}>
      {screen === "journeys" && (
        <PageHeader
          title={`${originName} ➜ ${destinationName}`}
          subtitle={`${getFormattedDateTime()}`}
          backButtonTitle="Home"
          textButtonTitle={showControls ? "Done" : "Change"} // Change title based on showControls
          showTextButton={true} // Show the text button by default
          textButtonOnClick={handleToggleClick} // Call the toggle function
          showTitleContainer={!showControls} // Conditionally hide TitleContainer based on showControls
        />
      )}
      {showControls && ( // Only render controls if showControls is true
        <Controls>
          <ButtonWrapper>
            <LocationButton screen={screen} onClick={() => openOverlay('from')}>
              <LabelWrapper>
                <LabelText screen={screen}>from</LabelText>
                <LocationText screen={screen}>{originName}</LocationText>
              </LabelWrapper>
            </LocationButton>
            <LocationButton screen={screen} onClick={() => openOverlay('to')}>
              <LabelWrapper>
                <LabelText screen={screen}>to</LabelText>
                <LocationText screen={screen}>{destinationName}</LocationText>
              </LabelWrapper>
            </LocationButton>
            <SwitchButton screen={screen} onClick={handleSwitchLocations}>
              <SwitchIcon screen={screen}>
                <ReactSVG src={upDownArrowsIcon} />
              </SwitchIcon>
            </SwitchButton>
          </ButtonWrapper>
          <ControlsWrapper>
            <DateTimeButton screen={screen} onClick={() => setDateTimeCardOpen(true)}>
              <ControlIcon screen={screen}>
                <ReactSVG src={calendarIcon} />
              </ControlIcon>
              <DateTimeText>
                <ControlText screen={screen}>
                  {`${getFormattedDateTime()}`}
                </ControlText>
              </DateTimeText>
            </DateTimeButton>
            <OptionsButton screen={screen} onClick={() => setOptionsCardOpen(true)}>
              <ControlIcon screen={screen}>
                <ReactSVG src={controlsIcon} />
              </ControlIcon>
              <ControlText screen={screen}>
                <ControlText screen={screen}>Options</ControlText>
              </ControlText>
            </OptionsButton>
          </ControlsWrapper>
          <SearchButton 
            onClick={handleSearchClick}  // Call handleSearchClick function when button is clicked
            >
            <SearchIcon>
              <ReactSVG src={searchIcon} />
            </SearchIcon>
            <SearchButtonText>
              <SearchButtonText>Find reliable trains</SearchButtonText>
            </SearchButtonText>
          </SearchButton>
          {(isLocationPickerOpen || isOptionsCardOpen || isDateTimeCardOpen) && (
            <Overlay>
              {isLocationPickerOpen && (
                <LocationPicker
                  onClose={closeOverlay}
                  onLocationSelect={handleLocationSelect}
                  recentlyChosen={recentlyChosen}
                />
              )}
              {isOptionsCardOpen && (
                <OptionsCard
                onClose={closeOverlay}
                onApply={handleOptions}
                initialOptions={localOptions}
                />
              )}
              {isDateTimeCardOpen && (
                <DateTimeCard 
                onClose={closeOverlay} 
                onApply={handleDateTime} 
                initialDate={date ? date.split('T')[0] : ''}
                initialTime={date ? date.split('T')[1] : ''}
                initialType={tripType}          
                />
              )}        
            </Overlay>
          )}
        </Controls>
      )}
    </Container>
  );
};

export default SearchControls;