import styled from "styled-components"
import * as TypographyStyles from '../TypographyStyles';
import { colors } from "../../utils/colors";
import { useNavigate } from 'react-router-dom';


const Container = styled('div')`
display: flex;
padding: 40px;
justify-content: space-between; /* Space between the columns */
align-items: flex-start;
background: ${colors.grey200};
width: 100%;
box-sizing: border-box; /* Include padding in the element's total width */
  @media (min-width: 768px) {
    padding: 64px 64px;  // Increased horizontal padding for desktop
  }
`

const Column = styled('div')`
display: flex;
flex-direction: column;
justify-content: center;
align-items: flex-start;
flex: 1; /* Allow each column to grow equally */
`

const Links = styled('div')`
display: flex;
flex-direction: column;
align-items: flex-start;
gap: 24px;
`

const Socials = styled('div')`
display: flex;
align-items: flex-start;
gap: 10px;
`

const Link = styled(TypographyStyles.BodyMRegular)`
color: ${colors.grey800};
cursor: pointer;
`


const Sitemap = () => {
    const navigate = useNavigate(); // Initialize the useNavigate hook

    const handleNavigation = (path: string) => {
        navigate(path);
    };

    return (
      <Container>
        <Column>
            <Links>
                <Link onClick={() => handleNavigation('/data')}>Data</Link>
                <Link onClick={() => handleNavigation('/contact')}>Contact</Link>
                <Link onClick={() => handleNavigation('/2024wrapped')}>2024 Wrapped</Link>
            </Links>
            <Socials>

            </Socials>
        </Column>
        <Column>
            <Links>
                <Link onClick={() => handleNavigation('/terms')}>Terms and Conditions</Link>
                <Link onClick={() => handleNavigation('/privacy')}>Privacy</Link>
            </Links>
        </Column>
      </Container>
    );
  };
  
  export default Sitemap;