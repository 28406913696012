import { useState, useRef, useEffect } from "react";
import styled from "styled-components"
import { ReactSVG } from 'react-svg';
import { colors } from "../../utils/colors";
import * as TypographyStyles from '../TypographyStyles';
import { CTAButtonHollow, ButtonM } from "../Controls/Buttons";
import { TextField, MultiLineTextField } from "../Controls/TextFields";

import earlier from '../../icons/earlier.svg';
import statistics from '../../icons/statisticsIcon.svg';
import transfer from '../../icons/updownarrows.svg';
import AI from '../../icons/AIicon.svg';
import bus from '../../icons/bus.svg';
import EUstars from '../../icons/EUstars.svg';
import government from '../../icons/government.svg';
import monitoring from '../../icons/monitoring.svg';
import plugin from '../../icons/plugin.svg';
import refresh from '../../icons/refresh.svg';
import report from '../../icons/report.svg';
import research from '../../icons/research.svg';


const Hero = styled('div')`
display: flex;
padding: 40px 10px;
flex-direction: column;
justify-content: center;
align-items: center;
gap: 24px;
align-self: stretch;
background: ${colors.keyDarker};
`

const HeroContent = styled('div')`
display: flex;
padding: 0px 36px;
flex-direction: column;
justify-content: center;
align-items: center;
gap: 18px;
align-self: stretch;
`

const ListContainersWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media (min-width: 768px) {
    flex-direction: row;
  }
`;

const ListContainer = styled('div')<{ backgroundColor?: string }>`
  display: flex;
  padding: 36px 34px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  background: ${({ backgroundColor }) => backgroundColor || colors.grey200};
  
  @media (min-width: 768px) {
    padding: 64px 64px;
    flex: 1;
  }
`;

const ArgumentContainer = styled('div')`
display: inline-flex;
align-items: flex-start;
gap: 8px;
`

const ArgumentText = styled('div')`
display: flex;
flex-direction: column;
align-items: flex-start;
gap: 6px;
`

const ArgumentIcon = styled("div")`
height: 24px;
display: flex;
justify-content: center;  /* Center horizontally */
align-items: center;      /* Center vertically */

  & svg {
    fill: ${colors.keyDarker};
    padding-top: 4px;  /* This will add padding to the top of the SVG */
    height: 16px;      /* Set height to 16px */
    width: auto;       /* Maintain aspect ratio */
  }
`;

const GetAccessContainer = styled("div")`
display: flex;
padding: 36px 34px;
flex-direction: column;
align-items: flex-start;
gap: 24px;
align-self: stretch;
background: ${colors.keyDarker};
  @media (min-width: 768px) {
    padding: 64px 64px;  // Increased horizontal padding for desktop
  }
`

const FormContainer = styled("div")`
display: flex;
flex-direction: column;
align-items: flex-start;
gap: 12px;
align-self: stretch;
`

const SuccessMessage = styled("div")`
display: flex;
padding: 16px 12px;
flex-direction: column;
justify-content: center;
align-items: center;
gap: 10px;
align-self: stretch;
border-radius: 12px;
background: ${colors.green300};
`

const ErrorMessage = styled("div")`
display: flex;
padding: 16px 12px;
flex-direction: column;
justify-content: center;
align-items: center;
gap: 10px;
align-self: stretch;
border-radius: 12px;
background: ${colors.red300};
`


interface ArgumentProps {
    icon: string;  // The path to the icon should be a string
    title: string;
    body: string;
}

const Argument = ({ icon, title, body }: ArgumentProps) => {
    return (
        <ArgumentContainer>
            <ArgumentIcon>
                <ReactSVG src={icon} />
            </ArgumentIcon>
            <ArgumentText>
                <TypographyStyles.PageSubtitleSemiBold color={colors.keyDarkest}>{title}</TypographyStyles.PageSubtitleSemiBold>
                <TypographyStyles.BodyLRegular color={colors.grey800}>{body}</TypographyStyles.BodyLRegular>
            </ArgumentText>
        </ArgumentContainer>
    );
};


const APIPage = () => {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [useCase, setUseCase] = useState("");
    const [isNameError, setIsNameError] = useState(false);
    const [isEmailError, setIsEmailError] = useState(false);
    const [emailErrorMessage, setEmailErrorMessage] = useState("");
    const [isUseCaseError, setIsUseCaseError] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    // Create a reference to the GetAccessContainer
    const getAccessRef = useRef<HTMLDivElement>(null);

    const validateEmail = (email: string) => {
        // Simple regex for email validation
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };
  
    const handleSubmit = async () => {
      let hasError = false;
  
      // Validate name field
      if (!name) {
        setIsNameError(true);
        hasError = true;
      } else {
        setIsNameError(false);
      }
  
      // Validate email field
      if (!email) {
        setEmailErrorMessage("This field is required");
        setIsEmailError(true);
        hasError = true;
      } else if (!validateEmail(email)) {
        setEmailErrorMessage("Please enter a valid email address");
        setIsEmailError(true);
        hasError = true;
      } else {
            setIsEmailError(false);
      }
  
      // Validate use case field
      if (!useCase) {
        setIsUseCaseError(true);
        hasError = true;
      } else {
        setIsUseCaseError(false);
      }
  
      if (!hasError) {
        // Prepare the data to send
        const formData = {
            name,
            email,
            useCase,
        };
  
      try {
        // Send POST request to Netlify function
        const response = await fetch('/.netlify/functions/sendApiEmail', {
            method: 'POST',
            headers: {
            'Content-Type': 'application/json',
            },
            body: JSON.stringify(formData),
        });
  
        if (response.ok) {
          // Handle successful response
          const data = await response.json();
          console.log(data.message);
          setIsSuccess(true); // Set success state to true
          setName('');
          setEmail('');
          setUseCase('');
          setErrorMessage("");  // Clear any previous error messages
        } else {
          // Handle error response
          const errorData = await response.json();
          console.error('Error:', errorData.message);
          setErrorMessage(errorData.message); // Set the error message to display
        }
      } catch (error) {
        console.error('Network error:', error);
        setErrorMessage("Network error. Please try again later."); // Set a network error message
      }
    }
  };

    // Function to scroll to the GetAccessContainer
    const handleScrollToForm = () => {
        getAccessRef.current?.scrollIntoView({ behavior: 'smooth' });
    };

    // Scroll to top on component mount
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to the top of the page
    }, []);

    return (
      <>
        <Hero>
            <HeroContent>
                <TypographyStyles.PageTitleSemiBold color={colors.white}>chuuchuu data</TypographyStyles.PageTitleSemiBold>
                <TypographyStyles.PageSubtitle color={colors.keyBackground} style={{ textAlign: 'center' }}>Train delay history and statistics for your use case</TypographyStyles.PageSubtitle>
            </HeroContent>
            <ButtonM onClick={handleScrollToForm}>Get access</ButtonM>  {/* Trigger scroll on click */}
        </Hero>
        <ListContainersWrapper>
            <ListContainer backgroundColor={colors.grey200}>
            <TypographyStyles.PageTitle color={colors.keyDarker}>Features</TypographyStyles.PageTitle>
                <Argument
                    icon={earlier}
                    title="Delay history"
                    body="Access arrival and departure delay history for thousands of train trips"
                />
                <Argument
                    icon={statistics}
                    title="Statistics"
                    body="Average delay, cancellation rate and punctuality metrics"
                />
                <Argument
                    icon={transfer}
                    title="Transfers"
                    body="Get transfer history and statistics for any transfer between trains in our database"
                />
                <Argument
                    icon={plugin}
                    title="Easy to integrate"
                    body="State-of-the-Art REST API, ready to be integrated for your use case"
                />
                <Argument
                    icon={EUstars}
                    title="Wide coverage"
                    body="Data available for intercity, long distance and international trains across Europe"
                />
                <Argument
                    icon={refresh}
                    title="Up-to-date"
                    body="Our data is always up-to-date to help you spot new evolutions as soon as possible"
                />
            </ListContainer>
            <ListContainer backgroundColor={colors.keyBackground}>
                <TypographyStyles.PageTitle color={colors.keyDarker}>Use cases</TypographyStyles.PageTitle>
                <Argument
                    icon={research}
                    title="Research"
                    body="Analyse the impact of strikes, weather events and other factors on train reliability"
                />
                <Argument
                    icon={AI}
                    title="Delay prediction"
                    body="Train your AI model with our data to predict how delays occur and propagate"
                />
                <Argument
                    icon={government}
                    title="Policy"
                    body="Make data-based decisions for national and EU legislation on passenger rights "
                />
                <Argument
                    icon={monitoring}
                    title="Monitoring"
                    body="Spot and track disruptions as soon as they come up"
                />
                <Argument
                    icon={bus}
                    title="Timetable planning"
                    body="Plan bus and rail connections with sufficient buffer around regular delays"
                />
                <Argument
                    icon={report}
                    title="Reporting"
                    body="Benchmark rail operators & countries, and disseminate punctuality reports"
                />
            </ListContainer>
        </ListContainersWrapper>
        <GetAccessContainer ref={getAccessRef}>
            <TypographyStyles.PageTitle color={colors.white}>Get access</TypographyStyles.PageTitle>
            <TypographyStyles.BodyLRegular color={colors.keyBackground}>Fill out the form and we will get in contact with you within 24 hours.</TypographyStyles.BodyLRegular>
            {!isSuccess ? ( // Conditionally render form or success message
                <>
                    <FormContainer>
                        <TextField
                            instruction="Name"
                            dynamicPlaceholder="e.g. John Delayhunter"
                            isDisabled={false}
                            isError={isNameError}
                            errorMessage="This field is required"
                            value={name}
                            isBackgroundDark={true}
                            onChange={(e) => {
                                setName(e.target.value);
                                if (isNameError) setIsNameError(false); // Reset error state on change
                            }}
                        />
                        <TextField
                            instruction="Email"
                            dynamicPlaceholder="e.g. jdelayhunter@email.com"
                            isDisabled={false}
                            isError={isEmailError}
                            errorMessage={emailErrorMessage}
                            value={email}
                            isBackgroundDark={true}
                            onChange={(e) => {
                                setEmail(e.target.value);
                                if (isEmailError) setIsEmailError(false); // Reset error state on change
                            }}
                        />
                        <MultiLineTextField
                            instruction="Describe your use case"
                            dynamicPlaceholder="e.g. I would like to use delay data to train my AI model"
                            isDisabled={false}
                            isError={isUseCaseError}
                            errorMessage="This field is required"
                            value={useCase}
                            isBackgroundDark={true}
                            onChange={(e) => {
                                setUseCase(e.target.value);
                                if (isUseCaseError) setIsUseCaseError(false); // Reset error state on change
                            }}
                        />
                    </FormContainer>
                    <CTAButtonHollow onClick={handleSubmit}>Request data access</CTAButtonHollow>
                    {errorMessage && ( // Conditionally render ErrorMessage if there's an error
                        <ErrorMessage>
                            <TypographyStyles.BodyLSemibold color={colors.red600}>{errorMessage}</TypographyStyles.BodyLSemibold>
                        </ErrorMessage>
                    )}
                </>
            ) : (
                <SuccessMessage>
                    <TypographyStyles.BodyXLSemibold color={colors.green600} style={{ textAlign: 'center' }}>Request sent!</TypographyStyles.BodyXLSemibold>
                </SuccessMessage>
            )}
        </GetAccessContainer>
      </>
    );
};
  
export default APIPage;